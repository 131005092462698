import React, { useEffect, useState } from "react";
import { Flex, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { ProductCart } from "./ProductCart/ProductCart";
import { FoodCart } from "./FoodCart/FoodCart";
import { ServiceCart } from "./ServiceCart/ServiceCart";
import { useNavigate, useParams } from "react-router-dom";
const ShoppingCart: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { tabNo } = useParams<{ tabNo?: string }>(); // Make tabNo optional


  const [activeTab, setActiveTab] = useState<number>(
    tabNo ? parseInt(tabNo, 10) : 0
  );

  useEffect(() => {
    setActiveTab(tabNo ? parseInt(tabNo, 10) : 0);
  }, [tabNo]);



  return (
    <Tabs
      onChange={(index) => {
        setActiveTab(index);
        navigate(`/cart/${index}`, { replace: true }); // Update URL when switching tabs
      }}
      index={activeTab}
    >
      <Flex justify={"center"} direction={"column"}>
        <Text
          mt={24}
          fontSize={25}
          fontWeight={"600"}
          color={"primary"}
          align="center"
          mb={8}
        >
          {t("My Cart")}
        </Text>
        <Flex justify={"center"}>
          <TabList width={"40%"} color={"#000"}>
            <Tab
              width={"33%"}
              fontWeight={"600"}
              color={activeTab === 0 ? "primary" : undefined}
            >
              {t("Products")}
            </Tab>
            <Tab
              width={"33%"}
              fontWeight={"600"}
              color={activeTab === 1 ? "primary" : undefined}
            >
              {t("Food Products")}
            </Tab>
            <Tab
              width={"33%"}
              fontWeight={"600"}
              color={activeTab === 2 ? "primary" : undefined}
            >
              {t("Services")}
            </Tab>
          </TabList>
        </Flex>
      </Flex>
      <TabPanels>
        <TabPanel>{activeTab === 0 && <ProductCart />}</TabPanel>
        <TabPanel>{activeTab === 1 && <FoodCart />}</TabPanel>
        <TabPanel>{activeTab === 2 && <ServiceCart />}</TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default ShoppingCart;
