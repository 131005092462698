import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Heading,
  Text,
  Flex,
} from '@chakra-ui/react';
import { T } from '../../../utils/ValiidationSchemas';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import VerticalPattern from '../../../components/Patterns/VerticalPattern';

// Define the form values type
interface ForgotPasswordFormValues {
  email: string;
}

// Validation schema using Yup
const ForgotPasswordSchema = (t: T) => Yup.object().shape({
  email: Yup.string().email(t('Invalid email address')).required(t('Email is required')),
});

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const initialValues: ForgotPasswordFormValues = { email: '' };
  const navigate  = useNavigate()

  return (
    <Box margin={'auto'} width={{ base: '90%', md: '50%' }} mt={20} p={6} borderWidth={1} borderRadius="md" boxShadow="lg">
        <VerticalPattern width={"58px"} topMargin={"208px"}/>

      <Heading as="h2" size="lg" mb={6} textAlign="center">
        {t('Forgot Password')}
      </Heading>
      <Text mb={6} textAlign="center">
        {t("Enter your Email and we will send you an OTP to reset your password.")}
      </Text>

      <Formik
        initialValues={initialValues}
        validationSchema={ForgotPasswordSchema(t)}
        onSubmit={(values, { setSubmitting }) => {
         navigate('/email-verification' , {state:{type:'resetPassword' , email:values?.email}})
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field name="email">
              {({ field, form }: any) => (
                <FormControl isInvalid={form.errors.email && form.touched.email} mb={4}>
                  <FormLabel htmlFor="email">{t('Email Address')}</FormLabel>
                  <Input {...field} id="email" placeholder={t('Enter your email')} />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Flex justifyContent={'flex-end'}>
              <Button
                type="submit"
                bgColor={'primary'}
                isLoading={isSubmitting}
                loadingText={t('Sending')}
              >
                {t('Send OTP')}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ForgotPassword;
