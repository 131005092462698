import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Image,
  Input,
  SimpleGrid,
  Text,
  Avatar,
} from "@chakra-ui/react"; // Import Chakra UI components as needed
import { shoppingCart } from "../../../assets/images";

import useGetData from "../../../api/useGetData";
import ApiRoutes from "../../../api/services/api-routes";
import { lang } from "../../../utils/utilsFunctions";
import usePostData from "../../../api/usePostData";
import { useTranslation } from "react-i18next";
import Rating from "../../../components/Rating";
import { useAppSelector } from "../../../redux/store";
import SavedButton from "../../../components/SaveButton";
import RatingAndReview from "../../../components/RatingAndReview";

import VariationFields from "../../../components/VariationFields";
import VariationCard from "../../../components/VariationCard";
import minus from "../../../assets/images/imgs/minus.svg";
import plus from "../../../assets/images/imgs/plus.svg";

import { useDispatch } from "react-redux";
import { updateCartTotal } from "../../../redux/reducerSlices/LengthOfCart/lengthOfCartSlice";
import {
  emptyImage,
  sustainabilityIcon,
  foodIcon,
} from "../../../assets/images";

import AppCarousel from "../../../components/AppCarousel";
import TrendingProductCard from "../../../components/TrendingProductCard";
import i18n from "../../../i18n";

interface Package {
  quantity: number;
  price: number;
  originalPrice: number;
}

interface Item {
  minPackage: Package;
  packages: Package[];
  stock: number;
}

type PriceOnSelectionProps = {
  selectedValue: Item;
};

const ProductDetails: React.FC = () => {
  const { productId } = useParams<{ productId: string }>(); // Access the 'id' parameter from the URL
  //   const [store, setStore] = useState<Store | null>(null); // State to hold the fetched store
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [itemQuantity, setItemQuantity] = useState<string | number | undefined>(
    1
  );
  const customerId = useAppSelector((state: any) => state?.auth?.authData?._id);
  const [selectedValue, setSelectedValue] = useState<any>({});
  const [defaultImgSrc, setDefaultImgSrc] = useState();
  const [showRelatedItems, setShowRelatedItems] = useState(false);

  const {
    data: productData,
    loading: getProductLoading,
    setData: setProductsData,
    getData: fetchProductById,
    onRefresh: productDataOnRefresh,
    loadMoreData,
    // refreshLoading: refreshLoadingProductData,
  } = useGetData({
    route: ApiRoutes?.getProductById,
    params: { _id: productId },
  });

  const {
    data: postData,
    loading: postCartLoading,
    setData: setPostData,
    postData: postCartData,
  } = usePostData();

  const addToCart = async function (
    customerId: string,
    variationId: string,
    quantity: number
  ) {
    const response = await postCartData({
      route: productData?.result?.isFood
        ? ApiRoutes?.addToBasket
        : ApiRoutes?.addToCart,
      params: { customer: customerId, item: variationId, quantity },
      successMsg: t("Product added successfully"),
    });

    if (!response?.error) {
      setItemQuantity(1);
      setSelectedValue((prev: any) => {
        return { ...prev, stock: prev.stock - quantity };
      });
      const index = productData?.result?.variations?.findIndex((item: any) => {
        return item._id === selectedValue?._id;
      });
      const variations = productData?.result?.variations;
      variations[index].stock = selectedValue?.stock - quantity;
      setProductsData((prev: any) => ({
        ...prev,
        result: { ...prev?.result, variations: variations },
      }));

      dispatch(updateCartTotal(quantity));
    }
  };
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Remove any non-numeric characters from the input value
    const numericValue = e.target.value
      ? parseInt(e.target.value.replace(/\D/g, ""))
      : "";
    // Update the itemQuantity state with the numeric value
    if (numericValue === 0) {
      return;
    }
    if (!numericValue) {
      setItemQuantity(numericValue);
    }
    if (typeof numericValue === "number") {
      numericValue > 0 && setItemQuantity(numericValue || 1); // Convert the numeric value to an integer
    }
  };

  useEffect(() => {
    if (productData?.result?.defaultVariation) {
      setSelectedValue(productData?.result?.defaultVariation);
      setDefaultImgSrc(
        productData?.result?.defaultVariation?.images?.[0]?.Location
      );
    } else {
      setSelectedValue(productData?.result?.variations?.[0]);
    }
  }, [productData?.result?.defaultVariation]);

  const isTheMinePackageIsTheDefaultPackage =
    JSON.stringify(selectedValue?.defaultPackage) ===
    JSON.stringify(selectedValue?.minPackage);
  const categoryId = productData?.result?.categories?.[0]?._id;
  console.log({ categoryId }, "productData");

  return (
    <Box mt={4}>
      <Container w={"75%"}>
        <Flex
          display={{ base: "block", md: "flex" }}
          justifyContent={"space-between"}
          direction={"row"}
        >
          <Flex
            justifyContent={"space-between"}
            width={{ base: "100%", md: "49%" }}
          >
            <Flex
              align={"center"}
              gap={2}
              direction={"column"}
              width={{ base: "25%", md: "18%" }}
            >
              {selectedValue?.images?.map((img: any) => {
                return (
                  <Box
                    cursor={"pointer"}
                    onClick={() => setDefaultImgSrc(img?.Location)}
                    width={"90%"}
                    height={"25%"}
                    maxHeight={"80px"}
                    borderWidth={defaultImgSrc === img?.Location ? 2 : 0}
                    padding={0.5}
                    borderColor="primary"
                  >
                    <Image
                      width={"100%"}
                      height={"100%"}
                      objectFit="cover"
                      src={img?.Location}
                    />
                  </Box>
                );
              })}
            </Flex>

            <Box position={"relative"}>
              <Image
                width={"100%"}
                height={"100%"}
                objectFit={"contain"}
                src={defaultImgSrc || (!getProductLoading && emptyImage)}
                objectPosition={"top center"}
              />
              <Box
                position={"absolute"}
                bottom={8}
                right={2}
                display={"flex"}
                gap={2}
              >
                {productData?.result?.isFood  && (
                  <Box>
                    <Image src={foodIcon} w={7} h={7} />
                  </Box>
                )}

                {productData?.result?.isSustainable  && (
                  <Box bg={"#fff"} rounded={"full"}>
                    <Image src={sustainabilityIcon} w={7} h={7} />
                  </Box>
                )}
              </Box>
            </Box>
          </Flex>

          {/* <Flex></Flex> */}

          <Flex
            direction="column"
            justifyContent={"space-between"}
            width={{ base: "100%", md: "48%" }}
            mt={{ base: 5, md: 0 }}
          >
            <Box>
              <Flex justifyContent={"space-between"}>
                <Box>
                  <Text fontSize={"xl"} fontWeight={"bold"} color={"primary"}>
                    {productData?.result?.[`name${lang}`]}
                  </Text>
                  <Flex alignItems={"center"} justifyContent={"flex-start"}>
                    <Flex alignItems={"center"}>
                      <Rating rating={productData?.result?.rating} />
                      <Text fontSize="13px" color={"#4F4F4FB2"}>
                        ({productData?.result?.reviewCount} {t("Reviews")})
                      </Text>
                      <Text color={"#4F4F4FB2"} mx={2}>
                        {" "}
                        |
                      </Text>
                    </Flex>
                    <Link
                      to={`/store-details/${productData?.result?.shop?._id}`}
                    >
                      <Text fontSize="13px" color={"#4F4F4FB2"}>
                        {t("By")}: {productData?.result?.shop?.[`name${lang}`]}
                      </Text>
                    </Link>
                  </Flex>
                  <Text>{productData?.result?.[`description${lang}`]}</Text>
                  {/* <Text>{selectedValue?.[`description${lang}`]}</Text> */}
                  <Text color={"green"}>
                    {selectedValue?.stock > 0 &&
                      `${selectedValue?.stock} ${t("in stock")}`}
                  </Text>
                </Box>
                {customerId !== "guest" && (
                  <SavedButton
                    variationId={
                      // selectedValue?._id ||
                      productData?.result?.variations[0]?._id
                    }
                  />
                )}
              </Flex>
              <Flex mt={4}>
                <VariationFields
                  onChange={(value) => {
                    setSelectedValue(value);
                    setDefaultImgSrc(value?.images?.[0]?.Location);
                  }}
                  selectedValue={selectedValue}
                  variations={productData?.result?.variations}
                />
              </Flex>

              <Text mt={"1em"} p={6} fontWeight={"bold"}>
                {t("Offers")}
              </Text>
              <Box>
                <Flex mb={4} justifyContent={"space-between"}>
                  <Box width={"100%"}>
                    <Box>
                      <Box width={"60%"}>
                        <Flex justifyContent={"space-around"}>
                          <Text color={"#625051"}>
                            {selectedValue?.minPackage?.quantity} {t("item")}
                          </Text>
                          <Text fontWeight={"bold"} color={"primary"}>
                            {" "}
                            {selectedValue?.minPackage?.price} {t("AED")}
                          </Text>
                          <Text
                            color={"GrayText"}
                            textDecorationLine={"line-through"}
                          >
                            {selectedValue?.minPackage?.originalPrice}{" "}
                            {t("AED")}
                          </Text>
                        </Flex>
                      </Box>

                      {!isTheMinePackageIsTheDefaultPackage && (
                        <Box width={"60%"}>
                          <Flex justifyContent={"space-around"}>
                            <Text color={"#625051"}>
                              {selectedValue?.defaultPackage?.quantity}{" "}
                              {t("item")}
                            </Text>
                            <Text fontWeight={"bold"} color={"primary"}>
                              {" "}
                              {selectedValue?.defaultPackage?.price} {t("AED")}
                            </Text>
                            <Text
                              color={"GrayText"}
                              textDecorationLine={"line-through"}
                            >
                              {selectedValue?.defaultPackage?.originalPrice}{" "}
                              {t("AED")}
                            </Text>
                          </Flex>
                        </Box>
                      )}

                      {selectedValue?.packages?.map(
                        (packageData: Package, index: any) => (
                          <Box width={"60%"}>
                            <Flex justifyContent={"space-around"}>
                              <Text color={"#625051"}>
                                {packageData?.quantity} {t("item")}
                              </Text>
                              <Text fontWeight={"bold"} color={"primary"}>
                                {" "}
                                {packageData?.price} {t("AED")}
                              </Text>
                              <Text
                                color={"GrayText"}
                                textDecorationLine={"line-through"}
                              >
                                {packageData?.originalPrice} {t("AED")}
                              </Text>
                            </Flex>
                          </Box>
                        )
                      )}
                    </Box>
                  </Box>
                </Flex>
              </Box>
            </Box>

            <Flex
              width={"100%"}
              alignContent={"flex-end"}
              justifyContent={"space-between"}
              wrap={"wrap"}
            >
              <Flex mb={1} direction={"row"}>
                <Button
                  isDisabled={customerId === "guest"}
                  color={"#000"}
                  borderColor="#000"
                  borderWidth={".5px"}
                  backgroundColor={"transparent"}
                  borderRightRadius={"0px"}
                  onClick={() => {
                    if (typeof itemQuantity == "number" && itemQuantity <= 1) {
                      setItemQuantity(1);
                      return;
                    }
                    if (!itemQuantity) {
                      setItemQuantity(1);
                    }
                    if (typeof itemQuantity == "number" && itemQuantity > 1) {
                      setItemQuantity((prev) => {
                        if (typeof prev == "number" && itemQuantity > 1) {
                          return prev - 1;
                        } else {
                          return 1;
                        }
                      });
                    }
                  }}
                >
                  <Image src={minus} />
                </Button>
                <Input
                  type="text"
                  borderWidth=".5px"
                  borderColor={"#000"}
                  borderRadius={"0px"}
                  width={"70px"}
                  textAlign={"center"}
                  value={
                    typeof itemQuantity == "number"
                      ? itemQuantity.toString()
                      : itemQuantity
                  }
                  onChange={handleTextChange}
                  isDisabled={customerId === "guest"}
                />
                <Button
                  isDisabled={customerId === "guest"}
                  borderColor="#000"
                  borderWidth={".5px"}
                  borderLeftRadius={"0px"}
                  backgroundColor={"primary"}
                  onClick={() => {
                    setItemQuantity((prev) => {
                      if (typeof prev == "number" && prev > 0) {
                        return prev + 1;
                      } else {
                        return 1;
                      }
                    });
                  }}
                >
                  <Image src={plus} />
                </Button>
              </Flex>

              <Box>
                {!!selectedValue?.stock && selectedValue?.stock !== 0 && (
                  <Button
                    backgroundColor={"#EAC07F"}
                    color={"primary"}
                    isDisabled={
                      !(
                        typeof itemQuantity === "number" &&
                        itemQuantity > 0 &&
                        customerId !== "guest"
                      )
                    }
                    onClick={() => {
                      typeof itemQuantity === "number" &&
                        addToCart(customerId, selectedValue?._id, itemQuantity);
                    }}
                  >
                    <Flex gap={2} alignItems={"center"}>
                      {t("Add to cart")}
                      <Avatar size="xs" src={shoppingCart} />
                    </Flex>
                  </Button>
                )}
                {customerId === "guest" && selectedValue?.stock !== 0 && (
                  <Link to={"/auth-tabs"}>
                    <Flex>
                      <Text
                        my={2}
                        color={"primary"}
                        opacity={0.7}
                        textAlign={"center"}
                        // borderBottom={"1px solid #88060D"}
                      >
                        {t("You have to")}
                      </Text>
                      <Text
                        my={2}
                        mx={1}
                        color={"primary"}
                        opacity={0.89}
                        textAlign={"center"}
                        borderBottom={"1px solid #88060D"}
                      >
                        {t("login")}
                      </Text>
                      <Text
                        my={2}
                        color={"primary"}
                        opacity={0.7}
                        textAlign={"center"}
                      >
                        {t("first")}
                      </Text>
                    </Flex>
                  </Link>
                )}
              </Box>

              {selectedValue?.stock === 0 && (
                <Text opacity={0.8} color={"primary"}>
                  {t("Out of Stock")}
                </Text>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex my={8} direction={"column"}>
          <Flex>
            <Flex mb={4} px={4} py={2} color={"white"} bg={"primary"}>
              <Text>{t("Description")}</Text>
            </Flex>
          </Flex>
          <Box>
            <Text>{selectedValue?.[`description${lang}`]}</Text>
          </Box>
        </Flex>

        {/*===VariationCard===*/}
        {productData?.result?.variations && (
          <>
            <Text color="primary" fontSize={24} py={6}>
              {t("Variations")}
            </Text>

            {/* <Flex direction={"row"} wrap={"wrap"} p={3} width={"100%"}> */}
            <>
              <SimpleGrid columns={{ base: 1, sm: 2, md: 2, lg: 3 }}>
                {productData?.result?.variations.map((item: any) => {
                  return (
                    <Grid
                      mb={"20px"}
                      onClick={() => {
                        setSelectedValue(item);
                        setDefaultImgSrc(item?.images?.[0]?.Location);
                      }}
                    >
                      <VariationCard
                        activeVariation={selectedValue?._id}
                        //  item={item}
                        variationId={item?._id}
                        price={item?.minPackage?.price}
                        description={item?.[`description${lang}`]}
                        productImageSrc={item?.images[0]?.Location}
                        productName={productData?.result?.[`name${lang}`]}
                      />
                    </Grid>
                  );
                })}
              </SimpleGrid>
            </>
            {/* </Flex> */}
          </>
        )}

        {/* =========================review section ============= */}
        {productData?.result && (
          <Flex mt="4em">
            <RatingAndReview
              totalRating={productData?.result?.rating}
              type="product"
              typeId={productData?.result?._id}
            />
          </Flex>
        )}

        {/* ==============Related Product  =======================*/}
        {categoryId && (
          <Flex
            mt={8}
            p={!showRelatedItems ? {} : { base: 2, md: 8 }}
            bg={!showRelatedItems ? "" : "rgba(246, 244, 244, 0.59)"}
            maxWidth={"100%"}
          >
            <AppCarousel
              dataType="Products"
              sectionRoute="/all-products"
              sectionSearch={`category=${categoryId}`}
              sectionTitle="Related Products"
              skipId={productId}
              params={{ categories: categoryId }}
              route={ApiRoutes?.listProducts}
              renderItem={(item) => (
                <TrendingProductCard
                  width={{ base: "100%", md: "90%" }}
                  product={item}
                />
              )}
              onSuccess={(data) => {
                setShowRelatedItems(data?.length > 0);
              }}
              listEmptyComponent={<></>}
            />
          </Flex>
        )}
      </Container>
    </Box>
  );
};

export default ProductDetails;
