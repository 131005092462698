import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import i18n from "../../../i18n";
import {
  appleStore,
  googlePlay,
  whiteLogo,
  paymentMethod,
} from "../../../assets/images";
import { useTranslation } from "react-i18next";
import { FaXTwitter, FaInstagram } from "react-icons/fa6";
import { LuLinkedin } from "react-icons/lu";
import { FiFacebook } from "react-icons/fi";
import { TbBrandSnapchat, TbBrandTiktok } from "react-icons/tb";
import ComplaintsForm from "../../complaintsForm/ComplaintsForm";

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    isOpen: isComplaintsOpen,
    onOpen: onComplaintsOpen,
    onClose: onComplaintsClose,
  } = useDisclosure();

  return (
    <Flex justify={"center"} mt={32} px={10} py={6} bgColor={"primary"}>
      <Box w={"85%"} color={" rgba(255, 255, 255, 1)"}>
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(4, 1fr)" }}
        >
          <GridItem textAlign={{ base: "center", md: "start" }}>
            <Image margin={{ base: "auto", md: "0" }} src={whiteLogo} />
            <Text
              mt={3}
              fontSize={"lg"}
              fontWeight={"bold"}
              color={"rgba(234, 192, 127, 1)"}
            >
              {t("Got questions? Call us !")}
            </Text>
            <Text
              style={{ unicodeBidi: "plaintext" }}
              textAlign={"left"}
              cursor={"pointer"}
            >
              <span style={{ fontFamily: "JosefinSansRegular" }}>
                +971 60 050 5006
              </span>
            </Text>
            <Text mt={3} fontSize={"lg"} color={"rgba(234, 192, 127, 1)"}>
              {t("Contact info")}
            </Text>
            <Text cursor={"pointer"} fontFamily={"JosefinSansRegular"}>
              info@sona3.ae
            </Text>
            <Flex style={{ marginTop: "20px" }} gap={3}>
              {/* <NavLink> */}
              <FiFacebook
                style={{ cursor: "pointer", fontSize: "21px" }}
                onClick={() => {
                  window.open(`https://www.facebook.com/sona3app`, "_blank");
                }}
              />

              <FaXTwitter
                style={{ cursor: "pointer", fontSize: "21px" }}
                onClick={() => {
                  window.open(`https://twitter.com/Sona3app`, "_blank");
                }}
              />

              <FaInstagram
                style={{ cursor: "pointer", fontSize: "21px" }}
                onClick={() => {
                  window.open(`https://www.instagram.com/sona3app/`, "_blank");
                }}
              />

              <TbBrandSnapchat
                style={{ cursor: "pointer", fontSize: "21px" }}
                onClick={() => {
                  window.open(
                    `https://www.snapchat.com/add/sona3app`,
                    "_blank"
                  );
                }}
              />
              <TbBrandTiktok
                style={{ cursor: "pointer", fontSize: "21px" }}
                onClick={() => {
                  window.open(`https://www.tiktok.com/@sona3app`, "_blank");
                }}
              />

              <LuLinkedin
                style={{ cursor: "pointer", fontSize: "21px" }}
                onClick={() => {
                  window.open(
                    `https://www.linkedin.com/company/sona3app/`,
                    "_blank"
                  );
                }}
              />
            </Flex>
          </GridItem>

          <GridItem
            textAlign={{ base: "center", md: "start" }}
            mt={{ base: 5, md: 0 }}
          >
            <Text
              textDecoration={"underline"}
              fontSize={"xl"}
              my={2}
              mb={4}
              color={"rgba(234, 192, 127, 1)"}
            >
              {t("For Customers")}
            </Text>

            <Text
              onClick={() => {
                window.open(
                  `https://sona3.ae/support?lang=${i18n.language}`,
                  "_blank"
                );
              }}
              cursor={"pointer"}
              lineHeight={"26px"}
            >
              {t("Contact Us")}
            </Text>

            {/* <Text cursor={"pointer"} my={3}>
              {t("RETURN POLICY")}
            </Text> */}

            <Text
              onClick={() => {
                window.open(
                  `https://sona3.ae/faqs?lang=${i18n.language}`,
                  "_blank"
                );
              }}
              cursor={"pointer"}
              my={3}
            >
              {t("FAQS")}
            </Text>
          </GridItem>

          <GridItem
            textAlign={{ base: "center", md: "start" }}
            mt={{ base: 5, md: 0 }}
          >
            <Text
              mt={3}
              fontSize={"xl"}
              textDecoration={"underline"}
              mb={4}
              color={"rgba(234, 192, 127, 1)"}
            >
              {t("For Sellers")}
            </Text>

            <Text
              onClick={() => {
                window.open(
                  `https://sona3.ae/about?lang=${i18n.language}`,
                  "_blank"
                );
              }}
              my={3}
              cursor={"pointer"}
            >
              {t("Who We Are ?")}
            </Text>
            <Text
              onClick={() => {
                window.open(`https://seller.sona3.ae`, "_blank");
              }}
              my={3}
              cursor={"pointer"}
            >
              {t("Become a Seller")}
            </Text>
          </GridItem>
          <GridItem
            textAlign={{ base: "center", md: "start" }}
            mt={{ base: 5, md: 0 }}
          >
            <Text
              mt={3}
              fontSize={"xl"}
              textDecoration={"underline"}
              mb={4}
              color={"rgba(234, 192, 127, 1)"}
            >
              {t("Services")}
            </Text>
            <Text
              onClick={() => {
                window.open(
                  `https://sona3.ae/privacy-policy?lang=${i18n.language}`,
                  "_blank"
                );
              }}
              cursor={"pointer"}
              my={3}
            >
              {t("Privacy Policy")}
            </Text>

            <Text
              onClick={() => {
                window.open(
                  `https://sona3.ae/terms-conditions?lang=${i18n.language}`,
                  "_blank"
                );
              }}
              my={3}
              cursor={"pointer"}
            >
              {t("Terms & Conditions")}
            </Text>

            <Text
              onClick={() => navigate("/sitemap")}
              my={3}
              cursor={"pointer"}
            >
              {t("Sitemap")}
            </Text>

            <Text onClick={onComplaintsOpen} my={3} cursor={"pointer"}>
              {t("Complaints & Suggestions")}
            </Text>
          </GridItem>
          <Modal
            size={"lg"}
            isOpen={isComplaintsOpen}
            onClose={onComplaintsClose}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalBody>
                <ComplaintsForm />
              </ModalBody>
            </ModalContent>
          </Modal>
        </Grid>
        <Flex direction={"column"} alignItems={"flex-end"}>
          <Flex
            gap={3}
            mb={3}
            justifyContent={{ base: "center", md: "start" }}
            h="50%"
            alignItems={"flex-end"}
          >
            <Image
              onClick={() => {
                window.open(
                  `https://play.google.com/store/apps/details?id=com.sona3customerapp`,
                  "_blank"
                );
              }}
              cursor={"pointer"}
              src={googlePlay}
              borderRadius={5}
              h="60%"
            />
            <Image
              onClick={() => {
                window.open(
                  "https://apps.apple.com/eg/app/sona3/id6736483587",
                  "_blank"
                );
              }}
              cursor={"pointer"}
              src={appleStore}
              borderRadius={5}
              h="60%"
            />
          </Flex>

          <Flex
            justifyContent={{ base: "center", md: "start" }}
            h="50%"
            alignItems={"flex-end"}
          >
            <Image src={paymentMethod} />
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Footer;
