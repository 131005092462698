import React from "react";
import { Box, Flex, Image } from "@chakra-ui/react";

import { vPattern } from "../../assets/images";

interface VerticalPatternProps {
  sideMargin?: number;
  width?: string;
  topMargin?: string;
}

const VerticalPattern = ({
  sideMargin = 10,
  width = "70px",
  topMargin="0px"
}: VerticalPatternProps) => {
  return (
    <>
      <Box
        position={"absolute"}
        top={topMargin}
        left={`${sideMargin}px`}
        zIndex={-1000}
        // height={"50%"}
        width={width}
        // border={"2px solid red"}
      >
        <Image src={vPattern} alt="logo" maxW={"100%"} />
      </Box>

      <Box
        position={"absolute"}
        top={topMargin}
        right={`${sideMargin}px`}
        zIndex={-1000}
        // height={"50%"}
        width={width}
        // border={"2px solid red"}
      >
        <Image src={vPattern} alt="logo" maxW={"100%"} />
      </Box>
    </>
  );
};

export default VerticalPattern;
