// WishlistCard.tsx

import React from "react";
import {
  Box,
  Image,

  Text,
  Flex,
  Img,

  Button,
} from "@chakra-ui/react";

import shoppingCart from "../assets/images/imgs/shopping-cart.svg";
import trash from "../assets/images/imgs/trash.svg";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { lang } from "../utils/utilsFunctions";


import { reminder, emptyImage } from "../assets/images";

import { eye } from "../assets/images";

import Rating from "./Rating";

type Image = {
  Location?: string;
};
interface Product {
  _id?: number;
  productName?: string;
  productId: string;
  descriptionAr?: string;
  descriptionEn?: string;
  minPackage?: { price?: number; originalPrice: string };
  images?: Array<Image>;
  rating?: number;
  defaultVariation: Product;
  defaultPackage?: { price?: number };
  product: { nameEn: string; nameAr: string; _id: string };
  shop: { nameEn: string; nameAr: string; _id: string };
}
interface WishlistCardProps {
  product: Product;
  addToCart: () => {};
  removeFromWishlist: () => {};
}

const WishlistCard: React.FC<WishlistCardProps> = ({
  product,
  addToCart,
  removeFromWishlist,
}) => {
  const { t } = useTranslation();
  return (
    <Flex
      direction={"column"}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="lg"
      // width="16em"
      width={'100%'}
      height="400px"
      // backgroundColor={"rgba(255, 253, 239, 0.57)"}
      // zIndex={1}
      // justify="center" // Center vertically
      align="center"
      // paddingY={"16px"}
    >
         
      <Box
        width={"100%"}
        height={"60%"}
        backgroundColor="#F9F0E3"
        backgroundImage={product?.images?.[0]?.Location || emptyImage}
        bgRepeat="no-repeat"
        backgroundPosition={"center"}
        backgroundSize={"cover"}
        p={2}
        position={"relative"}
      >
        {/* <Center height="13rem" marginBottom={"16px"}>  */}
        {/* <Box
          width={"100%"}
          height={"100%"}
          backgroundColor="#F9F0E3"
          backgroundImage={product?.images?.[0]?.Location || ""}
          bgRepeat="no-repeat"
          objectFit="contain"
          px={2}
        /> */}
        <Flex
          paddingBottom={"32px"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box>
            {product?.minPackage?.originalPrice !==
              product?.minPackage?.price && (
              <Button
                variant={"secondary"}
                borderRadius={"50px"}
                // paddingY={"3px"}
                // paddingX={"14px"}
                height={8}
                color={"#FFFFFF"}
              >
                <Image src={reminder} />
                <Text ml={1}>{t('Sale')}</Text>
              </Button>
            )}
          </Box>
          <Flex w={"21%"} justifyContent={"space-between"}>
            <Img src={shoppingCart} onClick={addToCart} cursor={"pointer"} />
            <Img src={trash} onClick={removeFromWishlist} cursor={"pointer"}  />
          </Flex>
          {/* <Rating rating={product?.rating || 0} /> */}
        </Flex>

        {/* </Center> */}

        {/* </Link> */}
        <Box position={"absolute"} bottom={1} right={2}>
          <Rating rating={product?.rating || 0} />
        </Box>
      </Box>
      <Flex direction={"column"} mt={2} width={"100%"} px={4}>
        <Flex>
          <Text mr={1} fontWeight={"bold"}>
          {t('By')} :
          </Text>
          <Text color={"primary"}> {product?.shop?.[`name${lang}`]}</Text>
        </Flex>
        {/* <Heading noOfLines={1} as="h3" size="md" mb="2">
      {product?.productName}
    </Heading> */}
        <Text color={"#886969"} mb="2" noOfLines={1}>
          {product?.[`description${lang}`]}
        </Text>
        <Flex justify="flex-start">
          <Text color="primary" fontWeight={"bold"} pr={"10px"}>
            ${product?.minPackage?.price?.toString()} {t("AED")}
          </Text>
          {product?.minPackage?.originalPrice !==
            product?.minPackage?.price && (
            <Text textDecoration="line-through" color="gray.600">
              {product?.minPackage?.originalPrice?.toString()} {t("AED")}
            </Text>
          )}

          {/* Additional information or actions can be added here */}
        </Flex>
        <Button
          variant={"outline"}
          marginTop={"10px"}
          size="md"
          borderRadius={"20px"}
          // width={"70%"}
          marginX={"auto"}
          // onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          //   e?.preventDefault();
          // }}
        >
          <Link to={`/product-details/${product?.product?._id}`}>
            <Flex justify={"center"}>
              <Image src={eye} mr={1} />
              <Text> {t("View Product")}</Text>
            </Flex>
          </Link>
        </Button>
      </Flex>
    </Flex>
  );
 
};

export default WishlistCard;
