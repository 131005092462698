import React from "react";
import { AlertIcon, Box, Button, Flex, Text, Alert } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface CheckoutProps {
  cartId: string;
  cartTotal: number;
  customerId: string;
  isFood: boolean;
  emptyCartFn: (customerId: string) => void;
  checkoutCartFn: (cartId: string) => void;
  cartOriginalTotal: number;
  cashback: number;
  hasPurchased?: boolean;
}

const CartButtons: React.FC<CheckoutProps> = ({
  cartId,
  cartTotal,
  customerId,
  isFood,
  emptyCartFn,
  checkoutCartFn,
  cartOriginalTotal,
  cashback,
  hasPurchased,
}) => {
  const isDiscount = !(cartOriginalTotal === cartTotal);
  const { t } = useTranslation();
  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="lg"
      p={3}
      mb={4}
    >
      <Flex
        alignItems="center"
        direction={"row"}
        wrap={"wrap"}
        justifyContent="space-between"
      >
        <Text
          color={"primary"}
          display={"flex"}
          fontWeight={"600"}
          fontSize={24}
        >
          {" "}
          {t("Total Price")} :
          {isDiscount && (
            <Text
              fontWeight={"400"}
              textDecorationLine={"line-through"}
              color={"gray"}
              mx={2}
              fontSize={24}
            >
              {" "}
              {cartOriginalTotal} {t("AED")}
            </Text>
          )}
          {cartTotal} {t("AED")}
        </Text>

        <Flex
          width={"22%"}
          direction={"row"}
          wrap={"wrap"}
          justifyContent={"space-between"}
        >
          <Button
            fontSize={20}
            py={2}
            px={4}
            bg="primary"
            onClick={() => emptyCartFn(customerId)}
            mr={2}
            mb={4}
          >
            {" "}
            {t("Empty Cart")}{" "}
          </Button>
          <Link to={`/checkout-order?cart_id=${cartId}&isFood=${isFood}`}>
            <Button fontSize={20} py={2} px={4}>
              {" "}
              {t("Checkout")}{" "}
            </Button>
          </Link>
        </Flex>
      </Flex>

      {!isFood && (
        <Text>
          {" "}
          &emsp;{" "}
          {t(
            "If you check out your order, it will likely be delivered within 3 days."
          )}
        </Text>
      )}

      {!!cashback && (
        <Alert status="success" mt={4}>
          <AlertIcon />
          {t("You have received a cashback of")} {cashback} {t("AED")}!
        </Alert>
      )}

      {!hasPurchased && (
        <Alert status="success" mt={4}>
          <AlertIcon />
          {t(
            "Complete your first order and receive 1% of its value as cashback"
          )}
          !
        </Alert>
      )}
    </Box>
  );
};

export default CartButtons;
