import React from "react";
import { Box, Image, Heading, Flex, Center, Text } from "@chakra-ui/react";
import { UAEFlag, category } from "../assets/images";
import { lang } from "../utils/utilsFunctions";
import { Link, useNavigate } from "react-router-dom";

interface Category {
  _id?: number;
  nameEn?: string;
  nameAr?: string;
  descriptionEn?: string;
  descriptionAr?: string;

  image?: {
    Location?: string;
  };
  type?: string;
}

interface CategoryCardProps {
  category?: Category;
}

const CategoryCard: React.FC<CategoryCardProps> = ({ category }) => {
  const navigate = useNavigate();
  const handleNavigate = function () {
    const path =
      category?.type === "shop"
        ? `/all-products-all-services?category=${category?._id}`
        // ? `/all-stores?category=${category?._id}`
        // : category?.type === "service"
        // ? `/all-services?category=${category?._id}`
        // : category?.type === "product"
        // ? `/all-products?category=${category?._id}`
        : "";

    navigate(path, {
      state: {
        categoryNameEn: category?.[`nameEn`],
        categoryDescriptionEn: category?.[`descriptionEn`],
        categoryNameAr: category?.[`nameAr`],
        categoryDescriptionAr: category?.[`descriptionAr`],
      },
      // search: `?category=${category?._id}`,
    });
  };

  return (
    // <Link to= {{
    //   pathname: path,
    //   //search: `?category=${category?._id}`,
    //   state: { categoryName: category?.[`name${lang}`] }
    // }}>
    <Box maxW="md" onClick={handleNavigate} cursor={"pointer"}>
      <Flex
        direction="column"
        alignItems="center"
        marginRight={"10px"}
        justifyContent={"space-between"}
     
        // width={"220px"}
      >
        <Image
          src={category?.image?.Location || UAEFlag}
          alt={category?.[`name${lang}`]}
          width={"100px"}
          height={"100px"}
          borderRadius={"50%"}
          objectFit={"cover"}
        />

        <Box p="10px 0">
          {/* <Heading as="h6" size="xs" mb="2" noOfLines={1}>
            {category?.[`name${lang}`]}
          </Heading> */}
          <Text style={{textTransform: "capitalize",fontWeight: "bold", textAlign: "center"}}>
          {category?.[`name${lang}`]}
          </Text>
        </Box>
      </Flex>
    </Box>
    // </Link>
  );
};

export default CategoryCard;
