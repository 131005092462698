import { Box, Flex, Heading, Text } from "@chakra-ui/layout";
import useIsMobile from "../../../../../hooks/use-is-mobile";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import "react-phone-input-2/lib/style.css";
import SocialLogin from "../SocialRegister";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import usePostData from "../../../../../api/usePostData";
import ApiRoutes from "../../../../../api/services/api-routes";
import { registerSchema } from "../../../../../utils/ValiidationSchemas";
import AppField from "../../../../../common/AppField";
import PhoneInput from "react-phone-input-2";
import AppButton from "../../../../../common/AppButton";
import {
  convertArabicToEnglishNumerals,
  validatePhoneNumber,
} from "../../../../../utils/utilsFunctions";
import {
  Checkbox,
  FormLabel,
  IconButton,
  Image,
  InputRightElement,
  Input,
} from "@chakra-ui/react";
import style from "../../style.module.css";
import { useDispatch } from "react-redux";
import { setAuth } from "../../../../../redux/reducerSlices/Auth/authSlice";
import { jwtDecode } from "jwt-decode";
import { listEmirates } from "../../../../../utils/utilsFunctions";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import { emirates } from "../../../../../utils/Constants";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { UAE_flag } from "../../../../../assets/images";
import moment from "moment";

interface RegisterFormValues {
  name: string;
  phone: string;
  email: string;
  password: string;
  country?: string;
  city?: string;
  street?: string;
  birthDate: string;
  agreeTerms: boolean;
}
export default function RegisterForm({
  switchToLogin,
}: {
  switchToLogin: any;
}) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [mobile] = useIsMobile();
  const [isFocus, setIsFocus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  // const [emirates, setEmirates] = useState<any>();

  useEffect(() => {
    // listEmirates().then((emirates) => setEmirates(emirates));
  }, []);

  const primary = "#88050D";
  const initialValues: RegisterFormValues = {
    name: "",
    email: "",
    phone: "",
    password: "",

    country: "UAE",
    city: "",
    street: "",
    birthDate: "",

    agreeTerms: false,
  };
  const formikRef = useRef<any>();

  const { data, loading, postData } = usePostData();
  const dispatch = useDispatch();
  const onSubmit = async (values: RegisterFormValues) => {
    const { agreeTerms, ...valuesWithoutAgreeTerms } = values;

    const joinDate = new Date()?.toISOString();
    const data = {
      ...valuesWithoutAgreeTerms,

      isEmailVerified: false,
      // isPhoneVerified: true,
      joinDate: joinDate,
      address: {
        country: values?.country || "",
        city: {
          name: values?.city || "",
        },
        street: values.street || "",
      },
    };

    delete data?.country;
    delete data?.city;
    delete data?.street;
    data.phone = values?.phone?.replace("971", "");

    const res = await postData({
      route: ApiRoutes?.register,
      data: data,
      successMsg: t("You have registered successfully"),
    });

    if (!res?.error) {
      localStorage.removeItem("loginType");

      if (!res?.result?.isPhoneVerified) {
        dispatch(
          setAuth({
            token: res?.token,
            authInfo: res?.result,
            authData: jwtDecode(res?.token),
          })
        );
        navigate("/email-verification");

      }
      if (res?.result?.isPhoneVerified && res?.result?.isEmailVerified) {
        navigate("/auth-tabs");
      }
    }
  };

  const handleFocus = () => {
    setIsFocus(true);
  };
  const handleBlur = () => {
    setIsFocus(false);
  };

  return (
    <>
      <Box py="8" px={{ base: 0, md: 8 }} w={mobile ? "100%" : "65%"}>
        <Flex justify="space-between"></Flex>

        <Formik
          innerRef={formikRef}
          validateOnMount={true}
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={registerSchema(t)}
        >
          {({ setFieldValue, values, errors }) => {
          
            return (
              <Flex direction={"column"}>
                <Form noValidate={true}>
                  <AppField
                    name="name"
                    type="text"
                    title="Full Name"
                    placeholder={t("Enter your full name here")}
                    required={true}
                  />
                  <AppField
                    name="email"
                    type="email"
                    title="Email"
                    placeholder="example@mail.com"
                    required={true}
                  />
                  <AppField
                    name="password"
                    title="Password"
                    placeholder="*********"
                    required={true}
                    type={showPassword ? "text" : "password"}
                    icon={
                      <InputRightElement>
                        <IconButton
                          aria-label={
                            showPassword ? "Hide password" : "Show password"
                          }
                          icon={!showPassword ? <ViewOffIcon /> : <ViewIcon />}
                          onClick={() => setShowPassword(!showPassword)}
                          variant="ghost"
                          _hover={{
                            backgroundColor: "transparent",
                          }}
                          boxShadow={"none"}
                          top={"5px"}
                        />
                      </InputRightElement>
                    }
                  />
                  {/* Phone Input field */}

                  {/* <Field name="phone" validate={validatePhoneNumber}>
                    {({ field, form }: FieldProps<any>) => (
                      <Box mt={4}>
                        <FormLabel
                          mb={1}
                          mt={4}
                          color={"#333"}
                          fontWeight={400}
                          htmlFor={"Phone Number"}
                        >
                          {t('Phone Number')}
                        </FormLabel>
                        <PhoneInput
                          inputStyle={{
                            paddingLeft: "70px",
                            borderColor: "#8A7475",
                            paddingRight: "29px",
                            width: "100%",
                            height: "35px",
                            fontSize: "1rem",
                            padding: "0.5rem",
                            borderRadius: "0.375rem",
                            backgroundColor: "#F9FCF5",
                            border: isFocus
                              ? "1px solid #F9FCF5 !important"
                              : "1px solid #CBD5E0",
                            boxShadow: isFocus
                              ? "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
                              : "0px 2px 2px 0px rgba(0, 0, 0, 0.25)",
                          }}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          containerStyle={{ marginBottom: "1rem" }}
                          buttonStyle={{
                            backgroundColor: "#EDF2F7",
                            display: "none",
                          }}
                          country={"ae"}
                          value={field?.value}
                          onChange={(value) => {
                            form.setFieldValue("phone", value);
                          }}
                          disableDropdown={true}
                          onlyCountries={["ae"]}
                          countryCodeEditable={false}
                          placeholder="+971 55 123 4567"
                        />

                        <ErrorMessage
                          className={style?.phoneError}
                          name="phone"
                          component="div"
                        />
                      </Box>
                    )}
                  </Field> */}

                  <Box mt={5}>
                    <FormLabel
                      mb={1}
                      mt={2}
                      color={"#4C5975"}
                      fontWeight={500}
                      fontSize={"16px"}
                      htmlFor={"phone"}
                    >
                      {t(`Phone Number`)}
                    </FormLabel>
                    <Flex gap={2} mt={-3}>
                      <Box>
                        <Flex
                          alignContent={"center"}
                          alignItems={"center"}
                          borderWidth={1}
                          boxShadow={"0px 2px 2px 0px rgba(0, 0, 0, 0.25)"}
                          bgColor={"#F9FCF5"}
                          justifyContent={"center"}
                          borderRadius={"10%"}
                          height={"50px"}
                          flex={1}
                          my={2}
                        >
                          <Image mx={1} width={"25%"} src={UAE_flag} />
                          {/* <span style={{ fontFamily: "JosefinSansRegular" }}> */}
                          +971
                          {/* </span> */}
                        </Flex>
                      </Box>
                      <Flex flex={1}>
                        <AppField name="phone" placeholder="Phone" />
                      </Flex>
                    </Flex>
                  </Box>

                  <AppField
                    name="city"
                    required={true}
                    type="select"
                    title={t("Emirate")}
                    placeholder={t("Select an emirate")}
                    data={emirates}
                    setFieldValue={setFieldValue}
                    onchange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setFieldValue("city", e.target.value);
                    }}
                  />

                  <AppField
                    name="street"
                    type="street"
                    title="Street"
                    placeholder="Street"
                    required={true}
                  />

                  <Box mb={1} mt={2}>
                    <Field name="birthDate">
                      {({ field, meta }: any) => (
                        <>
                          <FormLabel
                            mb={1}
                            mt={2}
                            color={"#4C5975"}
                            fontWeight={500}
                            fontSize={"16px"}
                            htmlFor="birthDate"
                          >
                            {t("Birth Date")}
                          </FormLabel>
                          <Input
                            className={style.custom_input}
                            {...field}
                            id="birthDate"
                            placeholder={t("Select birthDate")}
                            size="md"
                            type="date"
                            min="1895-01-01"
                            max="2006-12-31" // Set minimum date to January 1, 2006
                            value={convertArabicToEnglishNumerals(
                              moment(field.value).format("YYYY-MM-DD")
                            )} // Format the value using moment
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const selectedDate = moment(
                                e.target.value
                              ).toISOString();
                              setFieldValue("birthDate", selectedDate);
                            }}
                            boxShadow={"0px 2px 2px 0px rgba(0, 0, 0, 0.25)"}
                            backgroundColor={"rgb(229 229 229 / 41%)"}
                            onFocus={(e) => e?.currentTarget?.showPicker()} // Trigger the date picker on focus
                            // onBlur={(e)=>{ e.currentTarget.blur(); handleBlur("birthDate"); }}
                            onBlur={handleBlur}
                            _focus={{
                              borderColor: "#F9FCF5",
                              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                            }}
                            h={"50px"}
                            // className={meta.touched && meta.error ? 'is-invalid' : ''}
                          />
                          {meta.touched && meta.error && (
                            <div
                              className={style.error}
                              style={{
                                textAlign:
                                  i18n.language === "en" ? "left" : "right",
                              }}
                            >
                              {meta.error}
                            </div>
                          )}
                        </>
                      )}
                    </Field>
                    {/* <ErrorMessage name="birthDate" component="div" className={style.error} /> */}
                  </Box>

                  <Box mt={4} my={3} cursor={"pointer"}>
                    <Field name="agreeTerms" type="checkbox">
                      {({ field }: FieldProps<RegisterFormValues>) => (
                        <Flex alignItems={"center"}>
                          <Checkbox
                            required={true}
                            id="agreeTerms"
                            // isChecked={field?.value}
                            onChange={field?.onChange}
                            onBlur={field?.onBlur}
                          />

                          <FormLabel
                            onClick={() => {
                              window.open(
                                `https://sona3.ae/terms-conditions?lang=${i18n.language}`,
                                "_blank"
                              );
                            }}
                            cursor={"pointer"}
                            htmlFor="agreeTerms"
                            textDecoration={"underline"}
                            m={0}
                            ml={2}
                          >
                            <Text>
                              {t("I agree to the terms and conditions.")}
                            </Text>
                          </FormLabel>
                        </Flex>
                      )}
                    </Field>

                    <ErrorMessage
                      className={style.phoneError}
                      name="agreeTerms"
                      component="div"
                    />
                  </Box>
                  <Box mt={4}>
                    <AppButton
                      type="submit"
                      loading={loading}
                      title={t("Register")}
                      style={{ width: "100%", backgroundColor: "#a4020c" }}
                    />
                    <Flex mt={4} direction="row" gap=".2rem" justify={"center"}>
                      <Text color="">{t("Have an Account ?")}</Text>

                      <Text
                        cursor={"pointer"}
                        onClick={switchToLogin}
                        color="primary"
                        fontWeight="bold"
                      >
                        {t("Sign In")}
                      </Text>
                    </Flex>
                  </Box>
                </Form>
              </Flex>
            );
          }}
        </Formik>
      </Box>
    </>
  );
}
