import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Text,
  VStack,
  HStack,
  Image,
  Input,
  Center,
  IconButton,
  useToast,
  Flex,
  SimpleGrid,
  Grid,
} from "@chakra-ui/react";
import Review from "./Review";
import AddYourReview from "./AddYourReview";
import { EditIcon, DeleteIcon, CheckIcon } from "@chakra-ui/icons";
import useGetData from "../api/useGetData";
import ApiRoutes from "../api/services/api-routes";
import moveObjectToTop from "../utils/utilsFunctions";
import { useAppSelector } from "../redux/store";
// @ts-ignore
import ReactStars from "react-rating-stars-component";
import { useTranslation } from "react-i18next";
import usePutData from "../api/usePutData";
import useDeleteData from "../api/useDeleteData";
import ShowMore from "../common/ShowMore";
import { customerProfile } from "../assets/images";

interface ReviewItem {
  _id: string;
  reviewText: string;
  rating: number;
  customer: {
    name: string;
    isFound: boolean;
    image?: {
      Location: string;
    };
  };
  reviewDate: string;
}

interface Props {
  typeId: string;
  totalRating: number;
  type: string;
}

const RatingAndReview: React.FC<Props> = ({ typeId, totalRating, type }) => {
  const [edit, setEdit] = useState(false);
  const [yourReview, setYourReview] = useState("");
  // const [allReviews, setAllReviews] = useState([])
  const [isFound, setIsFound] = useState<any>();
  const [rating, setRating] = useState(0);
  const customerId = useAppSelector((state: any) => state?.auth?.authData?._id);
  const toast = useToast();
  const { t } = useTranslation();
  const {
    data: typeReviewData,
    loadingMoreData,
    setData: setReviewTypeData,
    getData: fetchReviewTypeData,
    loadMoreData,
  } = useGetData({
    params: {
      reviewOn: type,
      [type]: typeId,
      limit: 3,
      sortByDate:"reviewDate",
      sortOrder: -1,
    },
    route: ApiRoutes?.listReviews,
    onSuccess: (res: any) => {
      moveObjectToTop(res?.data?.result, customerId);
    },
  });

  const { deleteData, loading: deleteLoading } = useDeleteData();

  const { loading: loadingPutData, putData } = usePutData();

  const allReviews = typeReviewData?.result;

  useEffect(() => {
    fetchReviewTypeData({
      params: {
        reviewOn: type,
        [type]: typeId,
        limit: 3,
      sortByDate:"reviewDate",
        sortOrder: -1,
      },
      route: ApiRoutes?.listReviews,
    });
  }, [typeId, isFound, edit]);

  useEffect(() => {
    allReviews && setIsFound(allReviews[0]?.customer?.isFound);
    allReviews && setRating(allReviews[0]?.rating);
  }, [allReviews]);
  useEffect(() => {
    allReviews && setYourReview(allReviews[0]?.reviewText);
  }, [isFound, typeReviewData]);

  const handleUpdate = async () => {
    if (!(rating > 0)) {
      toast({
        title: "Rating is required",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    const response = await putData({
      route: ApiRoutes?.updateReview,
      params: {
        _id: allReviews[0]?._id,
        customer: allReviews[0]?.customer?._id,
      },
      data: {
        reviewOn: type,
        type: typeId,
        reviewText: yourReview,
        rating: rating,
        reviewDate: new Date().toISOString(),
      },
      successMsg: t("Review updated successfully"),
    });

    setEdit(false);

    // Implement update review logic
  };
  const handleEditClick = () => {
    setEdit(true); // Pre-populate input with existing text
  };
  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setYourReview(event?.target?.value);
  };
  const resetReview = () => {
    // Reset review text
    // setYourReview(reviews[0]?.reviewText || "");
    setEdit(false);
  };

  const handleDelete = async () => {
    // Implement delete review logic

    await deleteData({
      route: ApiRoutes?.deleteReview,
      params: {
        _id: allReviews[0]?._id,
        customer: allReviews[0]?.customer?._id,
      },
    });

    await fetchReviewTypeData({
      params: {
        reviewOn: type,
        [type]: typeId,
      sortByDate:"reviewDate",
        sortOrder: -1,
      },
      route: ApiRoutes?.listReviews,
      // onSuccess: (res: any) => {
      //   moveObjectToTop(res?.data?.result, customerId);
      // },
    });
  };

  return (
    <Flex width={"100%"} direction={"column"}>
      <Box mb={4}>
        <Text color="primary" fontSize={24}>
          {t("Ratings and Reviews")}
        </Text>
        <Box mt={2}>
          <Review
            rate={totalRating}
            // reviewNumber={allReviews?.length}
            reviewNumber={typeReviewData?.count}
            textColor="#3182CE"
          />
        </Box>
      </Box>

      {allReviews?.length ? (
        <>
          <SimpleGrid columns={{ base: 1, sm: 1, md: 2, lg: 3 }}>
            {allReviews?.map((review: any) => {
              const originalDate = new Date(review?.reviewDate);
              const formattedDate = `${originalDate.toLocaleString("default", {
                month: "short",
              })} ${originalDate.getDate()}, ${originalDate.getFullYear()}`;

              return (
                <Flex
                  direction={"column"}
                  key={review._id}
                  // spacing={4}
                  // align="stretch"
                  borderWidth="0.2px"
                  borderColor="#4D473E"
                  px={5}
                  py={2}
                  borderRadius={10}
                  mr={7}
                  // width={"29.2em"}
                  // height={"11.5em"}
                  mb={4}
                  // backgroundColor={"#F9EFDC"}
                >
                  <HStack spacing={4} justify="space-between">
                    <Flex
                      wrap={"wrap"}
                      alignContent={"center"}
                      justifyContent={"flex-start"}
                    >
                      <Image
                        borderRadius="full"
                        boxSize="4em"
                        src={
                          review.customer?.image?.Location
                            ? review.customer?.image?.Location
                            : customerProfile
                        }
                        mr={"1em"}
                        alt="Profile"
                      />
                      <Flex flexDirection={"column"} justifyContent={"center"}>
                        <Text fontSize="sm">{review.customer?.name}</Text>
                        <Box>
                          <ReactStars
                            edit={false}
                            count={5}
                            value={review?.rating}
                            //onChange={ratingChanged}
                            size={28}
                            isHalf={true}
                            // activeColor="#88050D"
                          />
                          <Review disappear rate={review?.rating} />
                        </Box>
                      </Flex>
                    </Flex>
                  </HStack>

                  <Box width={"100%"}>
                    {review?.customer.isFound ? (
                      <Input
                        style={
                          edit
                            ? {
                                border: "1px solid rgba(0, 0, 0, 0.6)",
                              }
                            : { border: "none" }
                        }
                        value={yourReview}
                        contentEditable={edit ? true : false}
                        onChange={handleTextChange}
                        readOnly={!edit}
                      />
                    ) : (
                      <Text noOfLines={2} color={"rgba(0, 0, 0, 0.6)"}>
                        {review?.reviewText}
                      </Text>
                    )}
                    <Text color="rgba(0, 0, 0, 0.6)" fontSize={"12px"}>
                      {formattedDate}
                    </Text>
                  </Box>
                  {review?.customer.isFound && (
                    <Flex justifyContent={"flex-end"}>
                      <DeleteIcon
                        cursor={"pointer"}
                        onClick={handleDelete}
                        color={"primary"}
                      />
                      {!edit && (
                        <EditIcon
                          cursor={"pointer"}
                          onClick={handleEditClick}
                          color={"primary"}
                          ml={6}
                        />
                      )}
                      {edit && (
                        <CheckIcon
                          cursor={"pointer"}
                          onClick={handleUpdate}
                          ml={6}
                          color={"primary"}
                        />
                      )}
                    </Flex>
                  )}
                </Flex>
              );
            })}
          </SimpleGrid>
          <ShowMore
            data={typeReviewData?.result}
            count={typeReviewData?.count}
            loadMoreData={loadMoreData}
            loading={loadingMoreData}
          />
        </>
      ) : (
        <Flex py={8} justify={"center"}>
          <Text fontSize={20} color={"primary"}>
            {" "}
            {t("No Reviews Yet")}
          </Text>
        </Flex>
      )}

      <Box>
        {!isFound && customerId !== "guest" && (
          <AddYourReview type={type} _id={typeId} setIsFound={setIsFound} />
        )}
      </Box>
    </Flex>
  );
};

export default RatingAndReview;
