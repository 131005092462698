import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useToast } from "@chakra-ui/react";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { showMSG, socialAuth } from "../utils/utilsFunctions";
import { setAuth } from "../redux/reducerSlices/Auth/authSlice";

const useFacebookAuth = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();



  const responseFacebook = async (response: any) => {
   
    try {
      const res2 = await socialAuth({
        token: response.access_token,
        name: response.name,
        email: response.email,
      });
    
      if (res2?.result) {
        localStorage.setItem("authInfo", JSON.stringify(res2?.result));
        localStorage.setItem("token", res2?.token);
        dispatch(
          setAuth({
            token: res2?.token,
            authInfo: res2?.result,
            authData: jwtDecode(res2?.token),
          })
        );
        showMSG(toast, "success", t("Logged in successfully"));
        navigate("/");
      }
    } catch (error) {
      showMSG(toast, "error", t("Unexpected Error happened !"));
      console.log("Error:", error);
    }
  };

  const onFailure = (error: any) => {
    console.log("Facebook login failed:", error);
  };

  return { responseFacebook, onFailure };
};

export default useFacebookAuth;
