import { Box, Button, Flex, Grid, Spinner, Text } from "@chakra-ui/react";
import React from "react";
import useGetData from "../../api/useGetData";
import ApiRoutes from "../../api/services/api-routes";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const SiteMap = () => {
  const navigate = useNavigate();

  const { i18n } = useTranslation();

  const { data, loading, loadingMoreData, loadMoreData, getData } = useGetData({
    route: ApiRoutes?.listCategories,
    // params: { type: filterType?.value, limit: 20 },
    params: { type: "shop", isSubCategory: false, limit: 100 },
  });

  if (loading) {
    return (
      <Box
        w={"100%"}
        display={"flex"}
        justifyContent={"center"}
        paddingTop={"5rem"}
      >
        <Spinner size="xl" color="#303030" />
      </Box>
    );
  }
  return (
    <Flex justifyContent={"center"}>
      <Flex
        direction={"column"}
        w={"80%"}
        paddingTop={"2rem"}
        color={"#303030"}
      >
        <Grid
          templateColumns={{
            base: "1fr", // 1 column on small screens
            md: "repeat(2, 1fr)", // 2 columns on medium screens
            lg: "repeat(4, 1fr)", // 4 columns on large screens
          }}
          gap={6} // Spacing between grid items
        >
          {data?.result.map((category: any) => (
            <Box key={category._id} p={4}>
              <Text
                onClick={() =>
                  navigate(
                    `/all-products-all-services?category=${category._id}`,
                    {
                      state: {
                        categoryNameEn: category?.[`nameEn`],
                        categoryDescriptionEn: category?.[`descriptionEn`],
                        categoryNameAr: category?.[`nameAr`],
                        categoryDescriptionAr: category?.[`descriptionAr`],
                      },
                    }
                  )
                }
                cursor={"pointer"}
                fontWeight="700"
                fontSize="20px"
                mb={"30px"}
                color={"#303030"}
                textTransform={"capitalize"}
              >
                {i18n.language === "ar" ? category.nameAr : category.nameEn}
              </Text>
              {category.subCategories.map((sub: any) => (
                <Text
                onClick={() =>
                  navigate(
                    `/all-products-all-services?category=${category._id}`,
                    {
                      state: {
                        categoryNameEn: category?.[`nameEn`],
                        categoryDescriptionEn: category?.[`descriptionEn`],
                        categoryNameAr: category?.[`nameAr`],
                        categoryDescriptionAr: category?.[`descriptionAr`],
                        selectedSubCategory: sub?._id
                      },
                    }
                  )
                }
                  key={sub._id}
                  mb={"16px"}
                  fontWeight="400"
                  fontSize="16px"
                  color="#545454"
                cursor={"pointer"}
                >
                  {i18n.language === "ar" ? sub.nameAr : sub.nameEn}
                </Text>
              ))}
            </Box>
          ))}
        </Grid>
      </Flex>
    </Flex>
  );
};

export default SiteMap;
