import React from 'react'
import { Box, Flex, Image } from "@chakra-ui/react";

import { hPatternMedium } from '../../assets/images';

interface HorizontalPatternProps {
    topMargin?: number;
    bottomMargin?: number;
    width?: string;
  }

const HorizontalPattern = ({ topMargin = 50,bottomMargin=0, width = "30%" }: HorizontalPatternProps) => {

  return (
    <>
    <Flex
      position={"absolute"}
      top={`${topMargin}px`}
      left={0}
      zIndex={-1000}
      // height={"100%"}
      width={width}
      // border={"2px solid red"}
    >
      <Image
        src={hPatternMedium}
        alt="logo"
        maxW={"100%"}
        // width={"100%"}
        // border={"2px solid red"}
      />
    </Flex>

    <Box
      position={"absolute"}
      bottom={`${bottomMargin}px`}
      right={0}
      zIndex={-1000}
      // height={"100%"}
      width={width}
      // border={"2px solid red"}
    >
      <Image src={hPatternMedium} alt="logo" maxW={"100%"} />
    </Box>
  </>
  )
}

export default HorizontalPattern