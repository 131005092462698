import React, { useEffect, useRef, useState } from "react";
import useGetData from "../api/useGetData";
import { useLocation } from "react-router-dom"; 
import ApiRoutes from "../api/services/api-routes";
import {
  Box,
  Flex,
  Heading,
  List,
  ListItem,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { lang } from "../utils/utilsFunctions";
import AppSkeleton from "../common/AppSkeleton";
import { useTranslation } from "react-i18next";
import ShowMore from "./ShowMore";
import { all } from "axios";

interface Category {
  _id: string;
  nameAr: string;
  nameEn: string;
}
interface CategoriesSidebarProps {
  route: string;
  params?: {};
  setItemId: any;
  customHeight?: string | number;
  title?: string;
  incomingSelectedId?: string;
  selectedIdFromParent?: string | null;
}
const CategoriesSidebar: React.FC<CategoriesSidebarProps> = ({
  route,
  params,
  setItemId,
  customHeight,
  title,
  incomingSelectedId,
  selectedIdFromParent,
}) => {
  const location = useLocation();
  const {pathname} = location;

  const [selectedItemId, setSelectedItemId] = useState<any>();
  const { data, loading, loadMoreData, loadingMoreData, setData } = useGetData({
    route,
    params,
    onSuccess: (res, setData) => {
      res.data.result = [
        { _id: `${title}`, nameEn: "All ", nameAr: "الكل" },
        ...res?.data?.result,
      ];
      //
      // console.log(res.data.result,'dddddd')
      // console.log({incomingSelectedId})
      // const selectedItemIndex = res.data?.result?.findIndex(
      //   (item: any) => item?._id === incomingSelectedId
      // );
      // console.log({selectedItemIndex},"selected")
      // if (selectedItemIndex !== -1) {
      //   // Remove the selected item from its current position
      //   const selectedItem = allData?.splice(selectedItemIndex, 1)[0];
      //   // Insert the selected item at the beginning of the array
      // const newData=  allData?.unshift(selectedItem);
      //   // Update the state with the modified results array
      //   setData([...newData]);
      //   console.log(newData,"hehe")
      // }
    },
  });

  useEffect(() => {
    setSelectedItemId(incomingSelectedId || title);
  }, [title, incomingSelectedId]);

  const { t } = useTranslation();
  const allData = data?.result;



  return (
    <Box borderRadius={8} backgroundColor={"secondary"} mr={8}>
      <Text
        mb={"8px"}
        fontWeight={"bold"}
        color={"rgba(29, 35, 44, 1)"}
        paddingX={5}
        paddingY={4}
        boxShadow={"0px 6px 8px -9px grey"}
        borderTopRadius={8}
        // borderRadius={10}
        position={"sticky"}
        fontSize={"1rem"}
        // margin={5}
        backgroundColor={"#fff9e6"}
      >
        {t("Filter By")} {t(`${title}`)}
      </Text>
      {loading ? (
        <AppSkeleton
          width={250}
          // height={customHeight || 400} // Apply custom height to the container
          height={200}
        />
      ) : (
        <Box
          px={5}
          width={250}
          // height={customHeight || 300} // Apply custom height to the container
          height={pathname === "/all-variations" ? 450 :200}
          overflowY="auto"
          pb={6}
          sx={{
            "&::-webkit-scrollbar": {
              width: "6px",
              height: "0px",
            },

            "&::-webkit-scrollbar-thumb": {
              background: "#EAC07F",

              borderRadius: 8,
            },
          }}
        >
          <List spacing={3} width={300}>
            {allData?.map((category: Category) => (
              <ListItem
                cursor="pointer"
                color={category?._id === selectedIdFromParent ? "primary" : "inherit"}
                // color={category?._id === selectedItemId ? "primary" : "inherit"}
                onClick={(e) => {
                  if (category?._id === `${title}`) {
                    setItemId(undefined);
                    setSelectedItemId(category?._id);
                  } else {
                    setItemId(category?._id);
                    setSelectedItemId(category?._id);
                  }
                }}
                key={category?._id}
              >
                {category?.[`name${lang}`]}
              </ListItem>
            ))}
          </List>

          <ShowMore
            small={false}
            data={data}
            loading={loadingMoreData}
            loadMoreData={loadMoreData}
            count={data?.count}
          />
        </Box>
      )}
    </Box>
  );
};

export default CategoriesSidebar;
