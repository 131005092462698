import React, { useEffect, useRef, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { Box, Button, Container, Flex, Image, Text } from "@chakra-ui/react"; // Import Chakra UI components as needed
import useGetData from "../../../api/useGetData";
import ApiRoutes from "../../../api/services/api-routes";

import Tabs from "../../../common/Tabs";
import SectionHeader from "../../../components/SectionHeader";
import {
  defaultVariationOfProducts,
  lang,
  onChangeDynamicFormFieldValue,
  onChangeDynamicFormFieldValueProps,
  validatedDynamicFormFields,
} from "../../../utils/utilsFunctions";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SavedButton from "../../../components/SaveButton";
import Rating from "../../../components/Rating";
import RatingAndReview from "../../../components/RatingAndReview";

import DynamicForm from "../../../components/DynamicForm";
import { Formik } from "formik";
import AppButton from "../../../common/AppButton";
import usePostData from "../../../api/usePostData";
import { useAppSelector } from "../../../redux/store";
import { updateCartTotal } from "../../../redux/reducerSlices/LengthOfCart/lengthOfCartSlice";
import { useDispatch } from "react-redux";
import SocketClass from "../../../utils/Socket";
import { useSocket } from "../../../Socket/SocketProvider";
import { emptyImage, servicesBg } from "../../../assets/images";
import AppCarousel from "../../../components/AppCarousel";
import ServiceCard from "../../../components/ServiceCard";

const ServiceDetails: React.FC = () => {
  const { serviceId } = useParams<{ serviceId: string }>();
  const navigate = useNavigate();
  const formRef = useRef<any>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showRequestServiceForm, setShowRequestServiceForm] = useState(false);
  const customerData = useAppSelector((state: any) => state?.auth?.authInfo);
  const socket = useSocket();
  const [showRelatedItems, setShowRelatedItems] = useState(false);
  const customerId = useAppSelector((state) => state?.auth?.authData?._id);

  const {
    data: serviceData,
    loading: getServiceDataLoading,
    onRefresh: onRefreshServiceDetails,
    // refreshLoading,
  } = useGetData({
    route: ApiRoutes?.getServiceById,
    params: { _id: serviceId },
  });
  const {
    data: postRequestServiceData,
    loading: postRequestServiceLoading,
    postData: postRequestService,
  } = usePostData();
  const serviceDetails = serviceData?.result;
  const requestService = async (fieldsValues: any) => {
    ///send request
    const currentDate = new Date();
    const isoFormattedDate = currentDate.toISOString();

    const response = await postRequestService({
      route: ApiRoutes?.requestService,
      data: {
        customer: customerData?._id,
        service: serviceId,
        shop: serviceDetails?.shop?._id,
        fields: fieldsValues,
        seller: serviceDetails?.seller?._id,
        issueDate: isoFormattedDate,
        shippingAddress: customerData?.location,
      },

      successMsg: t("Service requested successfully"),
    });

    if (!response?.error) {
      dispatch(updateCartTotal(1));
      socket?.emit(
        "sendCreationNotification",
        { request: response?.result?._id },
        (res: any) => {
          console.log({ res });
        }
      );
    }
  };

  const onSubmit = async (values: any) => {
    const fieldsValues = values?.form?.fields?.map((item: any) => {
      delete item.error;
      return {
        _id: item?._id,
        field: item,
        value: item?.value || (item?.type === "enum" ? null : ""),
      };
    });

    await requestService(fieldsValues);
  };

  const categoryId = serviceData?.result?.categories?.[0]?._id;

  return (
    <>
      <Flex justify={"center"}>
        <Flex direction={"column"} w={{ base: "85%", md: "75%" }}>
          <Text
            my={{ base: 5, md: 12 }}
            fontSize={30}
            fontWeight={"600"}
            color={"primary"}
            align="center"
          >
            {t("Service Details")}
          </Text>
          <Flex
            display={{ base: "block", md: "flex" }}
            justifyContent={"space-between"}
            direction={"row"}
          >
            <Box width={{ base: "100%", md: "40%" }}>
              <Image
                width={"100%"}
                // height={"100%"}
                objectFit={"cover"}
                src={
                  serviceDetails?.images?.[0]?.Location ||
                  (!getServiceDataLoading && emptyImage)
                }
                bg={"secondary"}
              />
            </Box>
            <Flex
              direction="column"
              justifyContent={"space-between"}
              width={{ base: "100%", md: "55%" }}
              // height={"300px"}
            >
              <Flex
                height={"100%"}
                direction={"column"}
                justifyContent={"flex-start"}
              >
                <Box mb={{ base: "1%", md: "8%" }}>
                  <Text fontSize={"xl"} fontWeight={"bold"} color={"primary"}>
                    {serviceDetails?.[`name${lang}`]}
                  </Text>
                  <Flex alignItems={"center"}>
                    <Rating rating={serviceDetails?.rating} />
                    <Text fontSize="13px" color={"#625051"}>
                      ({serviceDetails?.reviewCount} {t("Reviews")})
                    </Text>
                  </Flex>
                  <Text mt={2} color={"#000"}>
                    {t("By")} :
                    <Link to={`/store-details/${serviceDetails?.shop?._id}`}>
                        {serviceDetails?.shop?.[`name${lang}`]}
                    </Link>
                    
                  </Text>
                </Box>

                <Text mb={{ base: "1%", md: "8%" }}>
                  {serviceDetails?.[`description${lang}`]}
                </Text>

                <Text color={"#886969"}>
                  {t("Starts from")} : {serviceDetails?.basePrice} {t("AED")}
                </Text>

                {/* <Image width={"35px"} src={favourite} /> */}
                {/* <SavedButton
                  variationId={
                    // selectedValue?._id ||
                    serviceDetails?.result?.variations[0]?._id
                  }
                /> */}
              </Flex>
            </Flex>
          </Flex>
          {/* ============================requestService================= */}

          <Flex mt={{ base: 3, md: 16 }} direction={"column"}>
            <Flex direction={"column"}>
              <Text mb={4} color="primary" fontSize={24}>
                {t("Request Service")}
              </Text>
              <Text w={"100%"} color={"#886969"}>
                {t(
                  "Fill out the necessary information to request the service from the seller. The seller will contact you when the request is sent"
                )}
              </Text>
            </Flex>
            {serviceDetails?.fields && (
              <Formik
                onSubmit={onSubmit}
                initialValues={{
                  form: {
                    fields: serviceDetails?.fields?.map((item: any) => ({
                      ...item?.field,
                      value: null,
                    })),
                  },
                }}
                innerRef={formRef}
              >
                <>
                  <DynamicForm
                    onChange={(props: onChangeDynamicFormFieldValueProps) => {
                      const updatedData = onChangeDynamicFormFieldValue({
                        ...props,
                        fields: formRef?.current?.values?.form?.fields,
                      });
                      formRef?.current?.setFieldValue("form", {
                        ...formRef?.current?.values?.form,
                        fields: updatedData,
                      });
                    }}
                  />
                  <Flex mt={4} justify={"center"} direction={"row"}>
                    <Flex
                      alignItems={"center"}
                      justifyContent={"center"}
                      direction={"column"}
                      width={"100%"}
                    >
                      <AppButton
                        isDisabled={customerId === "guest"}
                        onClick={() => {
                          //send request anyway as they said🤷🏽‍♀️..revert
                          const values = formRef?.current?.values;

                          const updatedData = validatedDynamicFormFields({
                            t: t,
                            fields: values?.form?.fields,
                          });
                          if (updatedData !== true) {
                            formRef?.current?.setFieldValue("form", {
                              ...values?.form,
                              fields: updatedData,
                            });
                          } else {
                            formRef?.current?.handleSubmit();
                          }
                        }}
                        style={{
                          backgroundColor:"#EAC07F",
                          color: "#88050D",
                          minWidth: "35%",
                          fontSize: 22,
                          paddingBottom: 10,
                          paddingTop: 10,
                        }}
                        type="button"
                        loading={false}
                        title={t("Request Service")}
                      />
                      {customerId === "guest" && (
                        <Link to={"/auth-tabs"}>
                          <Flex>
                            <Text
                              my={2}
                              color={"primary"}
                              opacity={0.7}
                              textAlign={"center"}
                              // borderBottom={"1px solid #88060D"}
                            >
                              {t("You have to")}{" "}
                            </Text>
                            <Text
                              my={2}
                              mx={1}
                              color={"primary"}
                              opacity={0.89}
                              textAlign={"center"}
                              borderBottom={"1px solid #88060D"}
                            >
                              {""}
                              {t("login")}
                            </Text>
                            <Text
                              my={2}
                              color={"primary"}
                              opacity={0.7}
                              textAlign={"center"}
                            >
                              {t("first")}
                            </Text>
                          </Flex>
                        </Link>
                      )}
                    </Flex>
                  </Flex>
                </>
              </Formik>
            )}
          </Flex>
          {/* =========================review section ============= */}
          {serviceDetails && (
            <Flex mt="8em">
              <RatingAndReview
                totalRating={serviceDetails?.rating}
                type="service"
                typeId={serviceDetails?._id}
              />
            </Flex>
          )}

          {/* ========================Services Section======================= */}
          {categoryId && (
            <Flex
              alignItems={"flex-start"}
              bg={"rgba(246, 244, 244, 0.59)"}
              mt={16}
              p={showRelatedItems ? { base: 2, md: 8 } : {}}
              backgroundImage={showRelatedItems ? servicesBg : ""}
            >
              <AppCarousel
                dataType="Services"
                sectionRoute="/all-services"
                sectionTitle="Related Services"
                sectionSearch={`category=${categoryId}`}
                params={{ categories: categoryId }}
                skipId={serviceId}
                route={ApiRoutes?.listServices}
                renderItem={(item) => (
                  <ServiceCard
                    width={{ base: "100%", md: "90%" }}
                    service={item}
                  />
                )}
                listEmptyComponent={<></>}
                onSuccess={(data) => {
                  setShowRelatedItems(data?.length > 0);
                }}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default ServiceDetails;
