import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { firebaseErrorMessage, sendOTP } from "../../../utils/fireBaseInit";
import { showMSG } from "../../../utils/utilsFunctions";
import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";
import { RootState, useAppSelector } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import style from "./style.module.css";
import { FaLock } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import usePutData from "../../../api/usePutData";
import { logout, setAuth } from "../../../redux/reducerSlices/Auth/authSlice";
import { useTranslation } from "react-i18next";
import { jwtDecode } from "jwt-decode";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  User,
  ConfirmationResult,
  Auth,
  getAuth,
} from "firebase/auth";
import useTimer from "../../../hooks/useTimer";
import VerticalPattern from "../../../components/Patterns/VerticalPattern";

declare global {
  interface Window {
    recaptchaVerifier?: RecaptchaVerifier | null;
    confirmationResult?: ConfirmationResult | null;
  }
}
export default function PhoneVerification() {
  const [otpSend, setOtpSend] = useState<boolean>(false);

  const { time, start, isActive, reStart } = useTimer({
    initialTime: 60,
    onComplete: (setIsActive) => {
      setIsActive(false);
    },
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [otp, setOtp] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { putData } = usePutData();
  const authInfo = useAppSelector((state) => state?.auth?.authData);
  const authToken = useAppSelector((state) => state?.auth?.token);
  const token = useAppSelector((state) => state?.auth?.token);

  const [clickCounter, setClickCounter] = useState(0);

  const toast = useToast();


  async function onOTPVerify() {
    setClickCounter(clickCounter + 1);
    if (clickCounter === 3 || clickCounter > 3) {
      dispatch(logout());
    }
    setLoading(true);
    if (window.confirmationResult) {
      window.confirmationResult
        .confirm(otp)
        .then(async (res: any) => {
          const response = await putData({
            route: `verify?_id=${authInfo?._id}`,
            data: { isPhoneVerified: true },
          });
          if (response?.error) {
            /* handle error */
          } else {
            if (response?.result?.isPhoneVerified) {
              if (authToken && localStorage.getItem("loginType") === "social") {

                localStorage.setItem(
                  "authInfo",
                  JSON.stringify(response?.result)
                );
                localStorage.setItem("token", authToken || "");
                dispatch(
                  setAuth({
                    token: authToken,
                    authInfo: response?.result,
                    authData: jwtDecode(authToken || ""),
                  })
                );
                navigate("/");
                localStorage.removeItem("loginType");
              } else {

                showMSG(
                  toast,
                  "success",
                  t("phone number is verified successfully!")
                );
                navigate(`/auth-tabs`);
              }
              // return;
            } else {
              navigate(`/auth-tabs`);
              showMSG(
                toast,
                "success",
                t("phone number is verified successfully!")
              );
            }
          }
          setLoading(false);
        })
        .catch((err: any) => {
          showMSG(toast, "error", firebaseErrorMessage(err));

          setLoading(false);
        });
    } else {
      console.log("Confirmation result is null or undefined.");
      setLoading(false);
    }
  }

  const settingData = (response: any, message: string) => {
    dispatch(
      setAuth({
        token: token,
        authInfo: response?.data?.result,
        authData: token && jwtDecode(token),
      })
    );
    showMSG(toast, "success", t(`${message}`));
  };

  const sendOTPCode = async () => {
    if (!authInfo?.isPhoneVerified && !otpSend) {
      setLoading(true);
      await sendOTP(authInfo?.phone, toast, setLoading);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (otpSend && !authInfo?.isPhoneVerified) {
      sendOTP(authInfo?.phone, toast, setLoading);
      start();
      setOtpSend(false);
    }
  }, [otpSend]);

  useEffect(() => {
    sendOTPCode();
    start();
  }, []);

  const handlePaste: React.ClipboardEventHandler = (event) => {
    const data = event.clipboardData.getData("text");
    setOtp(data);
  };
  return (
    <div>
      <VerticalPattern width={"82px"} topMargin={"213px"} />

      <div id="recaptcha-container"></div>
      <Box className={style.otp_form}>
        <Box className={style.otp_icon_box}>
          <FaLock className={style.otp_icon} />
        </Box>
        <Box>
          <h1 className={style.otp_h1}> {t("Enter your OTP")} </h1>
          <Text>
            {t("We send you OTP to :")} {authInfo?.phone}{" "}
          </Text>
        </Box>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          inputType="number"
          onPaste={handlePaste}
          renderSeparator={<span>-</span>}
          renderInput={(props: any) => (
            <input id={style.input_otp} {...props} />
          )}
        ></OtpInput>
        <Button
          onClick={() => {
            onOTPVerify();
          }}
          isLoading={loading}
          isDisabled={otp.length !== 6}
          className={style.otp_btn}
        >
          {" "}
          {t("Verify OTP")}{" "}
        </Button>
        <Flex className={style.sentense_flex}>
          <Box className={style.sentense_flex_li}>
            {t("Didn’t receive the OTP?")}
          </Box>
          {isActive && <Text> {`${t("Retry in")} ${time}`}.</Text>}

          {!isActive && (
            <Box
              onClick={() => {
                // sendOTP(authInfo?.phone, toast, setLoading);
                reStart(60);
                setOtpSend(true);
              }}
              className={style.sentense_flex_li}
              id={style.sentense_flex_li_clickable}
            >
              {t("Click to resend")}
            </Box>
          )}
        </Flex>

        <Box onClick={() => navigate(`/auth-tabs`)} className={style.back}>
          {t("Back to log in")}{" "}
        </Box>
      </Box>
    </div>
  );
}
