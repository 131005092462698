import React, { useRef, useEffect, useState } from "react";
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  FormikHelpers,
  FieldProps,
} from "formik";
import * as Yup from "yup";
import AppField from "./../../common/AppField";
import usePostData from "./../../api/usePostData";
import usePutData from "./../../api/usePutData";
import { RootState } from "../../redux/store";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import useGetData from "./../../api/useGetData";
import ApiRoutes from "../../api/services/api-routes";
import {
  Button,
  Flex,
  Box,
  Input,
  Image,
  Spinner,
  Heading,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import style from "./style.module.css";
import { changePhoto, UAE_flag } from "./../../assets/images/index";
import { setAuthInfo } from "../../redux/reducerSlices/Auth/authSlice";
import { user } from "../../assets/images/index";
import {
  compareObjects,
  convertArabicToEnglishNumerals,
  listEmirates,
  mergeObjectsWithDifferences,
  showMSG,
  validatePhoneNumber,
} from "./../../utils/utilsFunctions";
import AppButton from "./../../common/AppButton";
import Identity from "../Auth/Register/Identity";
// import { emirates } from "../../utils/Constants";
import moment from "moment";
// import { emirates } from "../../utils/Constants";
import PhoneInput from "react-phone-input-2";
import { phoneValidationSchema, T } from "../../utils/ValiidationSchemas";
import { emirates } from "../../utils/Constants";
// Define validation schema using Yup

// const noSpecialCharacters = /^[a-zA-Z0-9 ]*$/;
// const noSpecialCharacters = /^[a-zA-Z0-9.,\u0600-\u06FF]+$/;
const noSpecialCharacters = /^[a-zA-Z0-9\u0600-\u06FF\s]*$/;

const validationSchema = (t: T) =>
  Yup.object().shape({
    name: Yup.string()
      .matches(noSpecialCharacters, t("No special characters allowed"))
      .required(t("Username is required")),

    phone: phoneValidationSchema(t),

    address: Yup.object().shape({
      city: Yup.string().required(t("City is required")),

      street: Yup.string()
        .matches(noSpecialCharacters, t("No special characters allowed"))
        .required(t("Street is required")),
    }),
    birthDate: Yup
      .date()
      .transform((value, originalValue) => {
        return typeof originalValue === 'string' ? new Date(originalValue) : value;
      })
      .required(t('Birth date is required'))
      .min(moment('1895-01-01').toDate(), t('Birth date must be on or after January 1, 1895'))
      .max(moment('2006-12-31').toDate(), t('Birth date must be on or before December 31, 2006'))
      .test('age', t('You must be at least 18 years old'), function (value) {
        const age = moment().diff(moment(value), 'years');
        return age >= 18;
      }),
  });

interface Address {
  city?: string;
  country?: string;
  street?: string;
}

interface FormValues {
  name: string;
  phone: string;
  address: Address;
  birthDate: string | undefined;
}
type SetAuthPayload = {
  authInfo: {};
};

const MyForm: React.FC = () => {
  const { loading: postLoading, postData } = usePostData();
  const { loading: loadingPutData, putData } = usePutData();
  const { authInfo } = useSelector<RootState, any | undefined>(
    (state) => state?.auth
  );

  const { t, i18n } = useTranslation();
  const formRef: any = useRef();
  const inputRef: any = useRef();
  const [previewImg, setPreviewImg] = useState();
  const dispatch = useDispatch();
  const toast = useToast();
  // const emirates = emirates
  // const [emirates, setEmirates] = useState<any>();

  useEffect(() => {
    // listEmirates().then((emirates) =>setEmirates(emirates))
  }, []);
  const onUploadImage = async (e: any) => {
    const image = e.target.files?.[0];
    setPreviewImg(image);
    const formData = new FormData();
    formData.append("image", image);

    if (image) {
      const res = await postData({
        route: `image`,
        data: formData,
        params: {
          _id: authInfo?._id,
        },
        successMsg: t("Image Updated Successfully"),
      });
      dispatch(setAuthInfo({ ...authInfo, image: res?.result?.image }));
    }
  };

  const initialValues: FormValues = {
    name: authInfo?.name || "",
    phone: authInfo?.phone || "",
    address: {
      city: authInfo?.address?.city?.name || "",
      country: authInfo?.address?.country || "",
      street: authInfo?.address?.street || "",
      //   emirate: authInfo?.address?.emirate || "",
    },
    birthDate: authInfo?.birthDate
      ? i18n.language === "ar"
        ? convertArabicToEnglishNumerals(
            moment(authInfo?.birthDate).format("YYYY-MM-DDTHH:mm")
          )
        : moment(authInfo?.birthDate).format("YYYY-MM-DDTHH:mm")
      : undefined,
  };
  const handleSubmit = async (
    values: FormValues,
    { setValues }: FormikHelpers<FormValues>
  ) => {
    const updatedData = compareObjects({
      oldObject: authInfo,
      newObject: values,
    });
    updatedData.address = {
      city: {
        name: values?.address?.city || "",
      },
      //  country: initialValues?.address?.country,
      country: "AUE",
      street: values?.address?.street || "",
      //   emirate: values?.address?.emirate || "",
    };

    const res = await putData({
      route: ApiRoutes?.updateProfile,
      data: updatedData,
      successMsg: t("Data Updated Successfully"),
      params: { _id: authInfo?._id },
    });

    setValues({
      name: res?.result?.name || "",
      phone: res?.result?.phone || "",
      address: {
        city: res?.result?.address?.city?.name || "",
        country: res?.result.address?.country || "",
        street: res?.result.address?.street || "",
        // emirate: res?.result.address?.emirate || "",
      },
      birthDate: res?.result?.birthDate,
    });
    const newAuthInfo = mergeObjectsWithDifferences(authInfo, updatedData);
    const newAuthInfoTyped: SetAuthPayload = newAuthInfo as SetAuthPayload;
    dispatch(setAuthInfo(newAuthInfoTyped));
    //showMSG(toast, "success", t("Data has been updated successfully!"));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema(t)}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {({
        isSubmitting,
        handleBlur,
        dirty,
        touched,
        values,
        errors,
        setFieldValue,
      }) => {
        return (
          <Flex
            className={style.form}
            justify={"center"}
            w={{ base: "100%", md: "75%" }}
          >
            <Form style={{ width: "100%" }}>
              <Box
                bgImage={
                  (!previewImg && !authInfo?.image?.Location && user) ||
                  (previewImg
                    ? URL.createObjectURL(previewImg)
                    : authInfo?.image?.Location)
                }
                boxShadow={"0px 2px 24px 0px rgba(0, 0, 0, 0.10)"}
                borderRadius={200}
                borderWidth={2}
                borderColor={"#A27878"}
                h={{ base: "15vh", md: "18vh" }}
                width={{ base: "15vh", md: "18vh" }}
                bgColor={"#F6EEE1"}
                bgSize={"cover"}
                overflow={"hidden"}
                margin={"5% auto"}
              >
                <Flex flexDirection={"column"} p={4} alignItems={"flex-end"}>
                  <Box
                    onClick={(e) => {
                      inputRef.current.click();
                    }}
                    p={1}
                    borderRadius={"50%"}
                    bgColor={postLoading ? "" : "rgba(0, 0, 0, 0.20)"}
                    cursor={"pointer"}
                  >
                    {!postLoading ? (
                      <Image margin={"0% auto"} src={changePhoto} />
                    ) : (
                      <Spinner />
                    )}
                    <Input
                      type="file"
                      accept="image/*"
                      hidden
                      ref={inputRef}
                      onChange={onUploadImage}
                    />
                  </Box>
                </Flex>
              </Box>
              <Box width={{ base: "100%", md: "85%" }} margin={"auto"}>
                <Box className={style.form_line}>
                  <AppField
                    disabled={loadingPutData}
                    name="name"
                    title="Name"
                    type="text"
                  />
                </Box>
                <Box className={style.form_line}>
                  <Field
                    name="phone"
                    // validate={validatePhoneNumber}
                  >
                    {({ field, form }: FieldProps<any>) => (
                      <Box mt={4}>
                        <Box mt={6}>
                          <FormLabel
                            mt={0}
                            my={1}
                            fontWeight={400}
                            color={"#4C5975"}
                            htmlFor={"phone"}
                          >
                            {t(`Phone Number`)}
                          </FormLabel>
                          <Flex gap={2} mt={-3}>
                            <Box>
                              <Flex
                                alignContent={"center"}
                                alignItems={"center"}
                                borderWidth={1}
                                boxShadow={
                                  "0px 2px 2px 0px rgba(0, 0, 0, 0.25)"
                                }
                                bgColor={"#F9FCF5"}
                                justifyContent={"center"}
                                borderRadius={"10%"}
                                height={"50px"}
                                flex={1}
                                mt={2}
                              >
                                <Image mx={1} width={"25%"} src={UAE_flag} />
                                {/* <span
                                  style={{ fontFamily: "JosefinSansRegular" }}
                                > */}
                                +971
                                {/* </span> */}
                              </Flex>
                            </Box>

                            <Flex flex={1}>
                              <AppField name="phone" placeholder="Phone" />
                            </Flex>
                          </Flex>
                        </Box>
                      </Box>
                    )}
                  </Field>
                </Box>

                <Box className={style.form_line}>
                  <AppField
                    name="address.city"
                    required={true}
                    type="select"
                    title={"City"}
                    touched={touched?.address?.city}
                    errorMsg={errors?.address?.city}
                    placeholder={`${initialValues?.address?.city}`}
                    data={emirates}
                    setFieldValue={setFieldValue}
                    onchange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setFieldValue("address.city", e.target.value);
                    }}
                  />
                </Box>

                <Box className={style.form_line}>
                  <AppField
                    errorMsg={errors?.address?.street}
                    touched={touched?.address?.street}
                    disabled={loadingPutData}
                    name="address.street"
                    title="Street"
                    type="text"
                  />
                </Box>

                <Box mb={1} mt={2}>
                  <Field name="birthDate">
                    {({ field, meta }: any) => (
                      <>
                        <FormLabel
                          htmlFor="birthDate"
                          mb={1}
                          mt={2}
                          color={"#4C5975"}
                          fontWeight={500}
                          fontSize={"16px"}
                        >
                          {t("Birth Date")}
                        </FormLabel>
                        {/* <input  type='date'/> */}
                        <Input
                          className={style.custom_input}
                          {...field}
                          id="birthDate"
                          placeholder="Select birthDate"
                          size="md"
                          type="date"
                          min="1895-01-01"
                          max="2006-12-31" // Set minimum date to January 1, 2006
                          value={convertArabicToEnglishNumerals(
                            moment(field.value).format("YYYY-MM-DD")
                          )} // Format the value using moment
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const selectedDate = moment(
                              e.target.value
                            ).toISOString();
                            setFieldValue(
                              "birthDate",
                              convertArabicToEnglishNumerals(selectedDate)
                            );
                          }}
                          boxShadow={"0px 2px 2px 0px rgba(0, 0, 0, 0.25)"}
                          backgroundColor={"#F9FCF5"}
                          onFocus={(e) => e?.currentTarget?.showPicker()} // Trigger the date picker on focus
                          // onBlur={(e)=>{ e.currentTarget.blur(); handleBlur("birthDate"); }}
                          onBlur={handleBlur}
                          _focus={{
                            borderColor: "#F9FCF5",
                            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                          }}
                          h={"50px"}
                          // className={meta.touched && meta.error ? 'is-invalid' : ''}
                        />
                        {meta.touched && meta.error && (
                          <div className={style.error}>{meta.error}</div>
                        )}
                      </>
                    )}
                  </Field>
                  {/* <ErrorMessage name="birthDate" component="div" className={style.error} /> */}
                </Box>
              </Box>
              <Button
                type="submit"
                isLoading={loadingPutData}
                isDisabled={isSubmitting || postLoading}
                className={style.submit_btn}
              >
                {t("Save Changes")}
              </Button>
            </Form>
          </Flex>
        );
      }}
    </Formik>
  );
};

export default MyForm;
