import { Box, Flex, Text } from "@chakra-ui/layout";
import {
  appleIcon,
  facebookIcon,
  googleIcon,
} from "../../../../../assets/images";
import useIsMobile from "../../../../../hooks/use-is-mobile";
import { useTranslation } from "react-i18next";
import { Button, Image } from "@chakra-ui/react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import useFacebookAuth from "../../../../../hooks/useFacebookAuth";
import useGoogleAuth from "../../../../../hooks/useGoogleAuth";

const FB_APP_ID = "1790929591415392";
// const FB_APP_ID="323181277082134" ; //production

interface SocialLoginProps {
  parent?: string;
}

export default function SocialLogin({ parent }: SocialLoginProps) {
  const [mobile] = useIsMobile();
  const { t } = useTranslation();
  const { responseFacebook, onFailure } = useFacebookAuth();
  const signInWithGoogle = useGoogleAuth(); // custom hook for google auth

  return (
    <>
      <Flex direction={mobile ? "column" : "column"} gap="1rem">
        <Flex
          w={mobile ? "100%" : "100%"}
          gap=".4rem"
          align="center"
          justify={"center"}
          border="1px"
          borderColor="gray.200"
          p=".4rem"
          py=".6rem"
          borderRadius="0.375rem"
          onClick={() => signInWithGoogle()}
        >
          <Flex>
            <img src={googleIcon} alt="google" />
          </Flex>
          <Text fontSize="md">
            {parent === "register"
              ? t("Sign up with Google")
              : t("Sign in with Google")}
          </Text>
        </Flex>
        {/* <Flex
          w={mobile ? "100%" : "100%"}
          // gap=".4rem"
          align="center"
          justify={"center"}
          border="1px"
          borderColor="gray.200"
          p=".4rem"
          py=".6rem"
          borderRadius="0.375rem"
        >
          <FacebookLogin
            appId={FB_APP_ID}
            autoLoad={false}
            fields="name,email,picture"
            callback={responseFacebook}
            onFailure={onFailure}
            render={(renderProps: any) => (
              <Flex cursor={"pointer"} onClick={renderProps.onClick}>
                <Flex mr={1.5} justify={"center"} > 
                  <Image src={facebookIcon} alt="facebook" h={4} />
                </Flex>
                <Text fontSize="md">{t("Sign in with Facebook")}</Text>
              </Flex>
            )}
          />
        </Flex> */}
      </Flex>
    </>
  );
}
