import * as yup from "yup";
import moment from 'moment';

export type T = (value: string) => string;

const enRegex = /^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/;
const arRegex = /^[\u0600-\u06FF0-9\s!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/;

const stringValidation = (originalValue: string) => {
  if (typeof originalValue === "string") {
    return originalValue.replace(/\s/g, "");
  }
  return originalValue;
};



// const phoneRegex = /^5[024568][0-9]{7}$/
// const phoneValidationSchema =  (t:T)=>yup.string().trim().required(t('Required*')).matches(phoneRegex , t('Please enter a valid UAE phone number'))
const phoneRegex = /^5[024568]\s*[0-9]{7}$/

export const phoneValidationSchema = (t: T) => 
  yup
    .string()
    .trim()
    .required(t('Phone number is required'))
    .transform(value => value.replace(/\s+/g, ''))  // Removes all spaces
    .matches(/^5/, t('Phone number must start with 5'))
    .matches(/^5[024568]/, t('Second digit must be 0, 2, 4, 5, 6, or 8'))
    .matches(phoneRegex, t('Please enter a valid UAE phone number, must be 9 digits'));


export const passwordValidationSchema = (t: T) => yup.string()
.required(t('Password is required'))
.min(8, t('Password must be at least 8 characters long'))
.matches(/[a-zA-Z]/, t('Password must contain at least one letter'))
.matches(/\d/, t('Password must contain at least one number'))

const stringEn = (t: T) =>
  yup
    .string()
    .trim()
    .transform(stringValidation)
    .min(3, t("at least 3 characters"))
    .matches(enRegex, t("invalid language"));
const stringAr = (t: T) =>
  yup
    .string()
    .trim()
    .transform(stringValidation)
    .min(3, t("at least 3 characters"))
    .matches(arRegex, "invalid language");

//==========================
//register new customer
//==========================
export const registerSchema = (t: T) => {
  // const noSpecialCharacters =/^[a-zA-Z0-9\u0600-\u06FF ]*$/;
  const noSpecialCharacters = /^[a-zA-Z0-9\u0600-\u06FF\s]*$/;


  return yup.object().shape({
    email: yup
      .string()
      .trim()
      .email(t("Invalid Email"))
      .required(t("Required*")),
    name: yup
      .string()
      .trim()
      .matches(noSpecialCharacters, t("No special characters allowed"))
      .required(t("Required*")),
    password: passwordValidationSchema(t),
    country: yup
      .string()
      .trim()
      .matches(noSpecialCharacters, t("No special characters allowed"))
      .required(t("Required*")),
    city: yup
      .string()
      .trim()
      // .matches(noSpecialCharacters, t("No special characters allowed"))
      .required(t("Required*")),
    street: yup
      .string()
      .trim()
      .matches(noSpecialCharacters, t("No special characters allowed"))
      .required(t("Required*")),
      birthDate: yup
      .date()
      .transform((value, originalValue) => {
        return typeof originalValue === 'string' ? new Date(originalValue) : value;
      })
      .required(t('Birth date is required'))
      .min(moment('1895-01-01').toDate(), t('Birth date must be on or after January 1, 1895'))
      .max(moment('2006-12-31').toDate(), t('Birth date must be on or before December 31, 2006'))
      .test('age', t('You must be at least 18 years old'), function (value) {
        const age = moment().diff(moment(value), 'years');
        return age >= 18;
      }),
      agreeTerms: yup
      .boolean()
      .oneOf([true], t("You must agree to the terms and conditions")),
      phone:phoneValidationSchema(t)

  });
};



export const addressSchema = (t: T) => {
  const noSpecialCharacters = /^[a-zA-Z0-9 ]*$/;

  return yup.object().shape({
    landMark: yup
      .string()
      .trim()
      .matches(noSpecialCharacters, t("No special characters allowed"))
      .required(t("Required*")),
    city: yup
      .string()
      .trim()
      .matches(noSpecialCharacters, t("No special characters allowed"))
      .required(t("Required*")),
    street: yup
      .string()
      .trim()
      .matches(noSpecialCharacters, t("No special characters allowed"))
      .required(t("Required*")),
    locationName: yup
      .string()
      .trim()
      .matches(noSpecialCharacters, t("No special characters allowed"))
      .required(t("Required*")),
  });
};

export const addressDetailsSchema = (t:T)=> yup.object().shape({
  city: yup.string().required(t("Please select an emirate")),
  // location: yup.object().required(t("Please select your location from the map")),
  street: yup.string().required(t("Street is required")),
  remarks: yup.string().required(t("Remarks is required")),
});