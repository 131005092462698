import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  FormLabel,
  Heading,
  Text,
  Flex,
  Image,
} from "@chakra-ui/react";
import { phoneValidationSchema, T } from "../../../utils/ValiidationSchemas";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import VerticalPattern from "../../../components/Patterns/VerticalPattern";
import AppField from "../../../common/AppField";
import { UAE_flag } from "../../../assets/images";
import usePutData from "../../../api/usePutData";
import ApiRoutes from "../../../api/services/api-routes";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useDispatch } from "react-redux";
import { setAuth } from "../../../redux/reducerSlices/Auth/authSlice";
import { jwtDecode } from "jwt-decode";
import { emirates } from "../../../utils/Constants";

// Define the form values type
interface AddPhoneNumberFormValues {
  phone: string;
  // country?: string;
  city?: string;
  // street?: string;
}

const noSpecialCharacters = /^[a-zA-Z0-9\u0600-\u06FF\s]*$/;
// Validation schema using Yup
const AddPhoneNumberSchema = (t: T) =>
  Yup.object().shape({
    phone: phoneValidationSchema(t),
    // country: Yup.string()
    //   .trim()
    //   .matches(noSpecialCharacters, t("No special characters allowed"))
    //   .required(t("Required*")),
    city: Yup.string().trim().required(t("Required*")),
    // street: Yup.string()
    //   .trim()
    //   .matches(noSpecialCharacters, t("No special characters allowed"))
    //   .required(t("Required*")),
  });

const AddPhoneNumber: React.FC = () => {
  const { t } = useTranslation();
  const { putData } = usePutData();
  const dispatch = useDispatch();
  const initialValues: AddPhoneNumberFormValues = { phone: "", city: "" };
  const navigate = useNavigate();
  const { authInfo, token } = useSelector<RootState, any | undefined>(
    (state) => state?.auth
  );

  const handleSubmit = async (values: AddPhoneNumberFormValues) => {
    const res = await putData({
      route: ApiRoutes?.updateProfile,
      data: {
        phone: values.phone,
        address: {
          country: "UAE",
          city: {
            name: values?.city || "",
          },
          street: "",
        },
      },
      successMsg: t("Profile Completed Successfully"),
      params: { _id: authInfo?._id },
    });

    if (!res?.error) {
      localStorage.setItem("loginType", "social");
      dispatch(
        setAuth({
          token: token,
          authInfo: res?.result,
          authData: res?.result,
        })
      );
      // return;

      navigate(`/phone-verification`);
    }
  };

  return (
    <Box
      margin={"auto"}
      width={{ base: "90%", md: "50%" }}
      mt={20}
      p={6}
      borderWidth={1}
      borderRadius="md"
      boxShadow="lg"
    >
      <VerticalPattern width={"58px"} topMargin={"208px"} />

      <Heading as="h2" size="lg" mb={6} textAlign="center">
        {t("Please Complete your Profile")}
      </Heading>
      <Text mb={6} textAlign="center">
        {t("Enter your Phone Number and we will send you an OTP.")}
      </Text>

      <Formik
        initialValues={initialValues}
        validationSchema={AddPhoneNumberSchema(t)}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, isSubmitting }) => (
          <Form>
            <Field name="phone">
              {({ field, form }: any) => (
                <Box mt={5}>
                  <FormLabel
                    mb={1}
                    mt={2}
                    color={"#4C5975"}
                    fontWeight={500}
                    fontSize={"16px"}
                    htmlFor={"phone"}
                  >
                    {t(`Phone Number`)}
                  </FormLabel>
                  <Flex gap={2} mt={-3}>
                    <Box>
                      <Flex
                        alignContent={"center"}
                        alignItems={"center"}
                        borderWidth={1}
                        boxShadow={"0px 2px 2px 0px rgba(0, 0, 0, 0.25)"}
                        bgColor={"#F9FCF5"}
                        justifyContent={"center"}
                        borderRadius={"10%"}
                        height={"50px"}
                        flex={1}
                        my={2}
                      >
                        <Image mx={1} width={"25%"} src={UAE_flag} />
                        +971
                      </Flex>
                    </Box>
                    <Flex flex={1}>
                      <AppField name="phone" placeholder="Phone" />
                    </Flex>
                  </Flex>
                </Box>
              )}
            </Field>

            <AppField
              name="city"
              required={true}
              type="select"
              title={t("Emirate")}
              placeholder={t("Select an emirate")}
              data={emirates}
              setFieldValue={setFieldValue}
              onchange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setFieldValue("city", e.target.value);
              }}
            />

            <Flex justifyContent={"flex-end"}>
              <Button
                type="submit"
                bgColor={"primary"}
                isLoading={isSubmitting}
                loadingText={t("Sending")}
                mt={5}
              >
                {t("Send OTP")}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AddPhoneNumber;
