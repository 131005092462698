export const mainBackground = require("./imgs/mainBackground.svg").default;
export const arrowRight = require("./imgs/arrowRight.svg").default;
export const category = require("./imgs/category.svg").default;
export const hrImg = require("./imgs/hrImg.svg").default;
export const logo = require("./imgs/logo.png");
export const logoEdit = require("./imgs/logo 1.png");
export const userIcon = require("./imgs/user.svg").default;
export const arrowDown = require("./imgs/arrowDown.svg").default;
export const UAEFlag = require("./imgs/UAEFlag.svg").default;
export const USAFlag = require("./imgs/usa.svg").default;

export const bell = require("./imgs/bell.svg").default;
export const shoppingCart = require("./imgs/shopping-cart.svg").default;
export const searchIcon = require("./imgs/searchIcon.svg").default;
export const filterIcon = require("./imgs/filterIcon.svg").default;
export const totalOrder = require("./imgs/totalOrder.svg").default;
export const totalOrderIcon = require("./imgs/totalOrderIcon.svg").default;
export const monthlyEarningsIcon =
  require("./imgs/monthlyEarningsIcon.svg").default;
export const vectorIcon = require("./imgs/Vector.svg").default;
export const polygonIcon = require("./imgs/Polygon 3.svg").default;
export const logoAuth = require("./imgs/logo 2 1.svg").default;
export const googleIcon = require("./imgs/Google.svg").default;
export const appleIcon = require("./imgs/apple.svg").default;
export const facebookIcon = require("./imgs/facebook.png");
export const expand = require("./imgs/expand.png").default;
export const favourite = require("./imgs/favorite.png").default;
export const profileImg = require("./imgs/Ellipse 77.svg").default;
export const redHeart = require("./imgs/heart.png").default;
export const saveIcon = require("./imgs/favIcon.png").default;
export const emptyBag = require("./imgs/emptyBag.svg").default;
export const backImg = require("./imgs/back.svg").default;
export const catIcon1 = require("./imgs/Vector (1).svg").default;
export const heart = require("./imgs/heart.svg").default;
export const layer = require("./imgs/layer.png").default;
export const arrowRight2 = require("./imgs/arrowRight2.svg").default;
export const reminder = require("./imgs/reminder.svg").default;
export const compare = require("./imgs/compare.svg").default;
export const eye = require("./imgs/eye.svg").default;
export const whiteLogo = require("./imgs/whiteLogo.svg").default;
export const changePhoto = require("./imgs/changePhoto.svg").default;
export const Photo = require("./imgs/Photo.svg").default;
export const user = require("./imgs/profile/user.svg").default;
export const bag = require("./imgs/profile/bag.svg").default;
export const security = require("./imgs/profile/security.svg").default;
export const credit = require("./imgs/profile/credit-card.svg").default;
export const payment = require("./imgs/profile/payment.svg").default;
export const locationIcon = require("./imgs/profile/location.svg").default;
export const settings = require("./imgs/profile/settings.svg").default;
export const blackHeart = require("./imgs/profile/blackHeart.svg").default;
export const bin = require("./imgs/bin.png").default;
export const edit = require("./imgs/edit.png").default;
export const servicesBg = require("./imgs/servicesBg.svg").default;
export const emptyLocations = require("./imgs/emptyLocations.svg").default;
export const compareIconWhite = require("./imgs/compareIconWhite.svg").default;
export const paymentMethod = require("./imgs/paymentMethod.svg").default;
export const hPattern = require("./imgs/h-pattern.png");
export const hPatternLong = require("./imgs/H - Long.svg").default;
export const hPatternMedium = require("./imgs/H - Medium.svg").default;
export const hPatternShort = require("./imgs/H - Short.svg").default;
export const vPatternPNG = require("./imgs/v-pattern.png");
export const vPatternLong = require("./imgs/V - Long.svg").default;
export const vPatternMedium = require("./imgs/V - Medium.svg").default;
export const vPattern = require("./imgs/V - Long - NoCut.svg").default;
export const vPatternShort = require("./imgs/V - Short.svg").default;
export const empty_chat = require("./imgs/empty.png");
export const person2 = require("./imgs/person2.png");
export const chat_empty = require("./imgs/chat_empty.png");
export const no_not = require("./imgs/no_not.png");
export const person = require("./imgs/person.png");
export const bell_ = require("./imgs/bell.png");
export const guest = require("./imgs/guest.png");
export const googlePlay = require("./imgs/googlePlay.jpeg");
export const appleStore = require("./imgs/appleStore.jpeg");
export const emptyImage = require("./imgs/emptyImage.png");
export const UAE_flag = require("./imgs/UAE_flag.png");
export const foodIcon = require("./imgs/foodIcon.png");
export const sustainabilityIcon = require("./imgs/sustainability_logo.png");
export const customerProfile = require("./imgs/profile.png");
export const signInLogo = require("./imgs/signInLogo.png");
export const signInLogo2 = require("./imgs/signInLogo2.jpeg");
export const signInLogo3 = require("./imgs/signInLogo3.jpeg");
export const cursor = require("./imgs/cursor.png");
