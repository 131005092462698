import React from "react";

import axios from "axios";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useToast } from "@chakra-ui/react";

import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import { setAuth } from "../redux/reducerSlices/Auth/authSlice";
import { showMSG, socialAuth } from "../utils/utilsFunctions";
import { useGoogleLogin } from "@react-oauth/google";

const useGoogleAuth = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();

  const signInWithGoogle = async (response: any) => {
    try {
      const res = await axios.get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${response.access_token}`,
        {
          headers: {
            Authorization: `Bearer ${response.access_token}`,
            Accept: "application/json",
          },
        }
      );
      const res2 = await socialAuth({
        token: response.access_token,
        name: res.data.name,
        email: res.data.email,
      });

      if (!res2?.error) {
        dispatch(
          setAuth({
            token: res2?.token,
            authInfo: res2?.result,
            authData: jwtDecode(res2?.token),
          })
        );
      }

      if (res2?.result.phone) {
        navigate(res2?.result.isPhoneVerified ? "/" : "/phone-verification");
        showMSG(toast, "success", t("Logged in successfully"));
      } else {
        navigate("/add-phone");
        showMSG(
          toast,
          "success",
          t("Logged in successfully, Please add your phone number")
        );
      }

      // localStorage.setItem("authInfo", JSON.stringify(res2?.result));
      // localStorage.setItem("token", res2?.token);

      // navigate("/");
    } catch (error) {
      showMSG(toast, "error", t("Unexpected Error happened !"));
    }
  };

  const onError = (error: any) => console.log("Login Failed:", error);

  const signInOptions = {
    onSuccess: signInWithGoogle,
    onError: onError,
  };

  // Call useGoogleLogin directly inside the component function
  const signIn = useGoogleLogin(signInOptions);

  return signIn; // Return the result
};

export default useGoogleAuth;
