import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { Box, Button, Flex, Text, useToast } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import style from "./style.module.css";
import { FaLock } from "react-icons/fa6";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import usePutData from "../../../api/usePutData";
import { RootState } from "../../../redux/store";
import useLogout from "../../../hooks/useLogout";
import usePostData from "../../../api/usePostData";
import { showMSG } from "../../../utils/utilsFunctions";
import {
  logout,
  setAuthInfo,
} from "../../../redux/reducerSlices/Auth/authSlice";
import VerticalPattern from "../../../components/Patterns/VerticalPattern";

export default function EmailVerification() {
  const [loading, setLoading] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { putData } = usePutData();
  const toast = useToast();
  const { handleLogout } = useLogout();
  const { postData, loading: loadingPostData, data } = usePostData();
  const location = useLocation();
  const state = location.state;
  const userData: any = useSelector<RootState>((state) => state?.auth.authInfo);
  const email = userData.email || state.email;
  const userId = state?.type === "resetPassword" ? data?.result : userData?._id;

  useEffect(() => {
    if (state?.type === "resetPassword" || state?.type === "login") {
      sendEmailOTP();
    }
  }, []);

  useEffect(() => {}, []);

  const sendEmailOTP = async () => {
    const res = await postData({
      route: `otp`,
      data: {
        email: email,
        type:
          state?.type === "changePassword" || state?.type === "resetPassword"
            ? "resetPassword"
            : undefined,
      },
    });
    console.log(res, "sendEmailOTP");
    if (!res?.error) {
      showMSG(
        toast,
        "success",
        `${t("We send you a confirmation email to")}: ${email} `
      );
    }
  };

  const changePassword = async () => {
    const data = {
      email: email,
      newPassword: state?.newPassword,
    };
    const res = await putData({
      route: `password?_id=${userData?._id}`,
      data: data,
    });
    if (res?.error) {
    } else {
      handleLogout();
      showMSG(
        toast,
        "success",
        t("Password updated successfully please login again")
      );
    }
  };

  const verifyEmail = async ({ res }: { res: any }) => {
    showMSG(toast, "success", `${t("Email verified successfully")}`);
    dispatch(setAuthInfo({ ...userData }));

    if (!userData?.isPhoneVerified && userData) {
      navigate(`/phone-verification`);
      return;
    } else {
      navigate(`/`);
      handleLogout();
    }
  };

  const [clickCounter, setClickCounter] = useState(0);

  const confirmCode = async () => {
    setClickCounter(clickCounter + 1);

    if (clickCounter === 3 || clickCounter > 3) {
      dispatch(logout());
    }
    const res = await postData({
      route: "verify",
      params: {
        _id: userId,
      },
      data: {
        otpCode: otp,
      },
    });

    if (!res?.error) {
      if (state?.type === "resetPassword") {
        navigate("/reset-password", {
          state: { email: email, token: res?.token, _id: userId },
        });
      } else if (state?.type === "changePassword") {
        changePassword();
      } else {
        verifyEmail(res);
      }
    }
  };

  const handlePaste: React.ClipboardEventHandler = (event) => {
    const data = event.clipboardData.getData("text");
    setOtp(data);
  };
  return (
    <div>
      <VerticalPattern width={"100px"} topMargin={"213px"} />

      <Box className={style.otp_form}>
        <Box className={style.otp_icon_box}>
          <FaLock className={style.otp_icon} />
        </Box>
        <h1 className={style.otp_h1}> {t("Enter your OTP")} </h1>
        <Text>
          {t("We send you OTP to :")}
          <span style={{ fontFamily: "JosefinSansRegular" }}>
            {" "}
            {email}{" "}
          </span>{" "}
        </Text>

        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          inputType="number"
          onPaste={handlePaste}
          renderSeparator={<span>-</span>}
          renderInput={(props: any) => (
            <input id={style.input_otp} {...props} />
          )}
        ></OtpInput>
        <Button
          onClick={() => {
            confirmCode();
          }}
          isLoading={loading}
          isDisabled={otp.length !== 6}
          className={style.otp_btn}
        >
          {" "}
          {t("Verify OTP")}{" "}
        </Button>
        <Flex className={style.sentense_flex}>
          <Box className={style.sentense_flex_li}>
            {t("Didn’t receive the OTP?")}
          </Box>
          <Box
            onClick={sendEmailOTP}
            className={style.sentense_flex_li}
            id={style.sentense_flex_li_clickable}
          >
            {t("Click to resend")}
          </Box>
        </Flex>
        <Box onClick={() => navigate(`/auth-tabs`)} className={style.back}>
          {t("Back to log in")}{" "}
        </Box>
      </Box>
    </div>
  );
}
