import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Flex,
  Tab,
  TabList,
  Tabs,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import useGetData from "../../api/useGetData";
import ApiRoutes from "../../api/services/api-routes";
import { lang } from "../../utils/utilsFunctions";
import StoreCard from "../../components/StoreCard";
import CategoriesSidebar from "../../components/CategoriesSidebar";
import ViewCards from "../../components/ViewCards";
import Banners from "../../components/Banners";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FilterDrawer from "../../components/FilterDrawer";
import TrendingProductCard from "../../components/TrendingProductCard";
import ServiceCard from "../../components/ServiceCard";

interface Store {
  id: number;
  name: string;
  // Add more properties as needed
}

const AllProductsAndServices: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get("category");
  const { t } = useTranslation();

  const categoryName = location.state?.[`categoryName${lang}`];
  const categoryDescription = location.state?.[`categoryDescription${lang}`];
  const [parentCategoryId, setParentCategoryId] = useState<any>(category);
  const [categoryId, setCategoryId] = useState<any>(
    location?.state?.selectedSubCategory
      ? location?.state?.selectedSubCategory
      : null
  );

  const [selectedCategoryId, setSelectedCategoryId] = useState<string>("");

  return (
    <Flex justify={"center"} py={16}>
      <Flex justifyContent={"center"} direction={"column"} width={"75%"}>
        <Text
          mt={4}
          mb={4}
          fontSize={30}
          fontWeight={"600"}
          color={"primary"}
          align="center"
        >
          {categoryName}
        </Text>

        <Flex alignItems={"center"} width={"100%"}>
          <FilterDrawer>
            <CategoriesSidebar
              title="Categories"
              customHeight={330}
              route={ApiRoutes?.listCategories}
              params={{
                type: "product",
                isSubCategory: true,
                parentCategory: parentCategoryId,
              }}
              setItemId={setCategoryId}
              incomingSelectedId={category || ""}
              selectedIdFromParent={categoryId}
            />
          </FilterDrawer>
          <Box zIndex={0} width={{ base: "100%", md: "76%" }}>
            {categoryDescription && (
              <Text mt={4} mb={4}>
                {categoryDescription}
              </Text>
            )}
            <Box bgColor={"#eee"}>
              <Banners height={"30vh"} route={ApiRoutes?.listFeaturedShops} />
            </Box>
          </Box>
        </Flex>

        <Text
          mt={16}
          mb={4}
          fontSize={30}
          fontWeight={"600"}
          color={"primary"}
          align="center"
        >
          {t("Products")}
        </Text>

        <ViewCards
          route={ApiRoutes?.listProducts}
          categoryId={categoryId}
          mainCategory={parentCategoryId}
          renderItem={(item) => <TrendingProductCard product={item} />}
        />

        <Text
          mt={4}
          mb={4}
          fontSize={30}
          fontWeight={"600"}
          color={"primary"}
          align="center"
        >
          {t("Services")}
        </Text>
        <ViewCards
          route={ApiRoutes?.listServices}
          categoryId={categoryId}
          mainCategory={parentCategoryId}
          renderItem={(item) => (
            <ServiceCard width={{ base: "100%", md: "90%" }} service={item} />
          )}
        />
      </Flex>
    </Flex>
  );
};

export default AllProductsAndServices;
