import {
  Image,
  InputGroup,
  Input,
  InputRightElement,
  Button,
  InputLeftElement,
  Card,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Spinner,
  Flex,
  Text,
  useHighlight,
  Mark,
  Select,
} from "@chakra-ui/react";
import { MdHistory, MdClose } from "react-icons/md";
import { IoCloseCircleOutline } from "react-icons/io5";
import { filterIcon, searchIcon } from "../assets/images";
import { useEffect, useRef, useState } from "react";

import useGetData from "../api/useGetData";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import useLang from "../hooks/useLang";

const PRODUCT_SEARCH_HISTORY_KEY = "searchProductHistory";
const SHOP_SEARCH_HISTORY_KEY = "searchShopHistory";
const SERVICE_SEARCH_HISTORY_KEY = "searchServiceHistory";

const saveSearchHistory = (id: string, newEntry: string, filterType: any) => {
  const key: any =
    filterType.label === "Product"
      ? PRODUCT_SEARCH_HISTORY_KEY
      : filterType.label === "Shop"
      ? SHOP_SEARCH_HISTORY_KEY
      : SERVICE_SEARCH_HISTORY_KEY;

  // const history = JSON.parse(localStorage.getItem(key) || "[]");
  // const updatedHistory = [
  //   newEntry,
  //   ...history.filter((item: string) => item !== newEntry),
  // ];

  // Parse existing history or initialize with an empty array
  const history = JSON.parse(localStorage.getItem(key) || "[]");

  // Filter out any existing item with the same id
  const updatedHistory = [
    { id, name: newEntry }, // Add new entry at the start
    ...history.filter((item: { id: string }) => item.id !== id),
  ];


  
  localStorage.setItem(
    filterType?.label === "Product"
      ? PRODUCT_SEARCH_HISTORY_KEY
      : filterType?.label === "Shop"
      ? SHOP_SEARCH_HISTORY_KEY
      : SERVICE_SEARCH_HISTORY_KEY,
    JSON.stringify(updatedHistory.slice(0, 10))
  ); // Limit to 10 entries
};

const getSearchHistory = (filterType: any) => {
  const key: any =
    filterType.label === "Product"
      ? PRODUCT_SEARCH_HISTORY_KEY
      : filterType.label === "Shop"
      ? SHOP_SEARCH_HISTORY_KEY
      : SERVICE_SEARCH_HISTORY_KEY;

  return JSON.parse(localStorage.getItem(key) || "[]");

  // if (filterType?.label === "Product") {
  //   return JSON.parse(localStorage.getItem(PRODUCT_SEARCH_HISTORY_KEY) || "[]");
  // } else if (filterType?.label === "Shop") {
  //   return JSON.parse(localStorage.getItem(SHOP_SEARCH_HISTORY_KEY) || "[]");
  // } else if (filterType?.label === "Service") {
  //   return JSON.parse(localStorage.getItem(SERVICE_SEARCH_HISTORY_KEY) || "[]");
  // }
};

const removeFromHistory = (id: string, filterType: any) => {
  const key: any =
    filterType.label === "Product"
      ? PRODUCT_SEARCH_HISTORY_KEY
      : filterType.label === "Shop"
      ? SHOP_SEARCH_HISTORY_KEY
      : SERVICE_SEARCH_HISTORY_KEY;

  // Get the current history
  const history = JSON.parse(localStorage.getItem(key) || "[]");

  // Filter out the item with the given id
  const updatedHistory = history.filter(
    (item: { id: string }) => item.id !== id
  );

  // Update the localStorage with the filtered history
  localStorage.setItem(key, JSON.stringify(updatedHistory));

  console.log("Item removed, updated history:", updatedHistory);
};

const SelectMenu = ({
  value,
  options,
  onChange,
}: {
  onChange: (value: { label: string; value: any }) => void;
  value: { label: string; value: any };
  options: { label: string; value: any }[];
}) => {
  return (
    <Select
      _focus={{
        borderColor: "#886969",
        boxShadow: "0px 0px 0px 0px #000000",
      }}
      borderColor={"secondary"}
      borderRightRadius={0}
      onChange={async (e) => {
        const value = await JSON.parse(e?.target?.value);
        onChange(value);
      }}
      textAlign={"left"}
      mr={2}
      defaultValue={"option1"}
      width={{ base: "7rem", md: "8rem" }}
      size={{ base: "sm", sm: "sm", md: "md" }}
      bgColor={"#E1E1E1"}
      fontSize={{ base: "12px", md: "15px" }}
    >
      {options?.map((item: any) => (
        <>
          <option value={JSON.stringify(item)}>{item?.label}</option>
        </>
      ))}
    </Select>
  );
};

const SearchItem = ({
  item,
  image,
  search,
  onClick,
}: {
  item: any;
  image: any;
  search: string;
  onClick: () => void;
}) => {
  const { lang } = useLang();

  const chunks = useHighlight({
    text: item?.[`name${lang}`],
    query: [search],
  });

  return (
    <Flex
      key={item?._id}
      zIndex={20}
      onClick={onClick}
      cursor={"pointer"}
      fontWeight={"normal"}
      alignItems={"center"}
      _hover={{ fontWeight: "bold" }}
      px={5}
      py={3}
      borderBottomWidth={1}
    >
      <Image
        borderRadius={2}
        height={"3vh"}
        width={"3vh"}
        src={image?.Location}
      />
      {/* <Text px={3}>{item?.[`name${lang}`]}</Text> */}
      <Box mx={3}>
        {chunks.map(({ match, text }) => {
          if (!match) return text;
          return text === "instantly" ? (
            <Box as="u" fontFamily="NewYork">
              {text}
            </Box>
          ) : (
            <Mark color={"primary"} fontWeight={"bold"}>
              {text}
            </Mark>
          );
        })}
      </Box>
    </Flex>
  );
};

export function SearchInput() {
  const {
    data,
    loading: loadingSearchResult,
    getData,
    setData,
  } = useGetData({ notLoadData: true, route: "", params: {} });
  const [isFocus, setIsFocus] = useState(false);
  const [search, setSearch] = useState<string>("");
  const [searchAutoComplete, setSearchAtoComplete] = useState<string>("");

  const { lang, isAr } = useLang();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const filterOptions = [
    { label: t("Product"), value: { route: "products/list" } },
    { label: t("Shop"), value: { route: "shops/list" } },
    { label: t("Service"), value: { route: "services/list" } },
  ];

  const [filterType, setFilterType] = useState(filterOptions?.[0]);

  const [searchHistory, setSearchHistory] = useState<any[]>(
    getSearchHistory(filterType) || []
  );

  useEffect(() => {
    setSearchHistory(getSearchHistory(filterType));
  }, [filterType]);

  //==============
  //AutoComplet
  //==============
  useEffect(() => {
    let myTimeout: number;
    if (search !== searchAutoComplete) {
      let myTimeout = setTimeout(() => {
        setSearchAtoComplete(search?.trim());
      }, 800);
    }
    return () => {
      clearTimeout(myTimeout);
    };
  }, [search]);

  useEffect(() => {
    if (
      search === searchAutoComplete &&
      !loadingSearchResult &&
      search?.length > 0
    ) {
      loadSearchResult(search, filterType);
    }
  }, [searchAutoComplete, filterType]);

  const loadSearchResult = async (
    search: string,
    value: { label: string; value: any }
  ) => {
    if (search) {
      const res = await getData({
        route: value?.value?.route,
        params: {
          nameEn: search,
          nameAr: search,
          descriptionAr: search,
        },
      });
    }
  };

  const handleSearch = (id: string, query: string) => {
    if (!query) return;
    saveSearchHistory(id, query, filterType);
    setSearchHistory(getSearchHistory(filterType));
    setSearchAtoComplete(query); // Ensure autocomplete aligns
    loadSearchResult(query, filterType);
    setIsFocus(false); // Close dropdown after search
  };

  const handleHistoryClick = (query: any) => {
    setSearch(query?.name);
    setSearchAtoComplete(query?.name);
    handleSearch(query?.id, query?.name);
    setIsFocus(false);
  };

  const selectMenu = (
    <>
      <SelectMenu
        options={filterOptions}
        onChange={(value) => {
          setFilterType(value);
          if (search) {
            setData(null);
            loadSearchResult(search, value);
            setIsFocus(true);
          }
        }}
        value={filterType}
      />
      {<Image h={{ base: 4, md: 5 }} src={searchIcon} alt="searchIcon" />}
    </>
  );

  const searchButton = (
    <>
      <Button
        bgColor={"primary"}
        borderLeftRadius={0}
        left={4}
        paddingY={"1rem"}
        paddingX={{ md: "2.8rem" }}
        size={{ base: "xs", sm: "sm", md: "md" }}
        // onClick={() => {}}
        // onClick={() => handleSearch(search)}
      >
        {t("Search")}
      </Button>
    </>
  );

  return (
    <>
      <Box width={"100%"}>
        <InputGroup
          zIndex={2}
          py={{ base: 1, md: 0 }}
          alignItems={"center"}
          size="lg"
          width={{ base: "100%", md: "100%" }}
        >
          {!isAr ? (
            <InputLeftElement
              h={"100%"}
              minWidth={{ base: "7rem", md: "8.5rem" }}
            >
              {selectMenu}
            </InputLeftElement>
          ) : (
            <>
              <InputRightElement h={"100%"} width="4.5rem">
                {searchButton}
              </InputRightElement>
            </>
          )}

          <Input
            _focus={{
              borderColor: "secondary",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              background: "#fff",
            }}
            borderColor={"secondary"}
            value={search}
            background={"white"}
            pr={
              isAr
                ? { base: "4.5rem", md: "6.5rem" }
                : { base: "4.5rem", md: "4.5rem" }
            }
            pl={{ base: "7rem", md: "9rem" }}
            type={"text"}
            placeholder={t("Search...")}
            borderEndRadius={isAr ? 5 : 0}
            size={{ base: "xs", sm: "sm", md: "md" }}
            onFocus={(state) => {
              setIsFocus(true);
            }}
            onChange={(e) => {
              let trimmedText = e.target.value === "  " ? "" : e.target.value;
              trimmedText = trimmedText.replace(/\s+/g, " ");
              trimmedText = trimmedText.replace(/[+*]+/g, "");
              setSearch(trimmedText);
              if (!trimmedText) {
                setData(null);
              }
            }}
          />

          {!isAr ? (
            <InputRightElement h={"100%"} width="4.5rem">
              {searchButton}
            </InputRightElement>
          ) : (
            <>
              <InputLeftElement
                h={"100%"}
                minWidth={{ base: "7rem", md: "8.5rem" }}
              >
                {selectMenu}
              </InputLeftElement>
            </>
          )}
        </InputGroup>
        {isFocus && (
          <Card
            backgroundColor={"#fff"}
            zIndex={2}
            overflowY={"scroll"}
            mx={"7%"}
            mt={1}
            position={"absolute"}
            maxHeight={"30vh"}
            width={{ base: "60%", md: "34%" }}
          >
            {loadingSearchResult && (
              <Flex m={2} justifyContent={"flex-end"}>
                {" "}
                <Spinner size={"sm"} color="primary" />{" "}
              </Flex>
            )}
            {data?.result?.map((item: any) => {
              const type = filterType?.label;
              const image =
                type === "Product"
                  ? item?.defaultVariation?.images?.[0] || item?.variation?.[0]
                  : type === "Service"
                  ? item?.images?.[0]
                  : item?.image;

              return (
                <SearchItem
                  onClick={() => {
                    setIsFocus(false);
                    handleSearch(item?._id, item?.[`name${lang}`]);
                    setSearchAtoComplete(item?.[`name${lang}`]);
                    if (type === t("Shop")) {
                      navigate(`/store-details/${item?._id}`);
                    }
                    if (type === t("Product")) {
                      navigate(`/product-details/${item?._id}`);
                    }

                    if (type === t("Service")) {
                      navigate(`/service-details/${item?._id}`);
                    }
                  }}
                  search={search}
                  item={item}
                  image={image}
                />
              );
            })}
            {searchHistory.map((historyItem) => (
              <Flex
                key={historyItem}
                alignItems={"center"}
                justifyContent={"space-between"}
                px={5}
                py={3}
                borderBottomWidth={1}
                _hover={{ bg: "gray.100" }}
              >
                <Flex
                  alignItems={"center"}
                  gap={2}
                  onClick={() => {
                    handleHistoryClick(historyItem);

                    
                    if (filterType?.label === t("Shop")) {
                      navigate(`/store-details/${historyItem?.id}`);
                    }
                    if (filterType?.label === t("Product")) {
                      navigate(`/product-details/${historyItem?.id}`);
                    }

                    if (filterType?.label === t("Service")) {
                      navigate(`/service-details/${historyItem?.id}`);
                    }
                  }}
                  cursor="pointer"
                  w={"90%"}
                >
                  <MdHistory />
                  <Text>{historyItem.name}</Text>
                </Flex>
                <MdClose
                  width={"2%"}
                  onClick={() => {

                    removeFromHistory(historyItem.id, filterType);
                    setSearchHistory(getSearchHistory(filterType)); // Update the state
                  }}
                  cursor="pointer"
                />
              </Flex>
            ))}
          </Card>
        )}
      </Box>
      {isFocus && (
        <Box
          onClick={() => {
            setIsFocus(false);
          }}
          top={0}
          left={0}
          position={"absolute"}
          width={"100%"}
          height={"100vh"}
          bgColor={"#00000050"}
          zIndex={1}
        >
          {" "}
        </Box>
      )}
    </>
  );
}
