import { Box, Button, Center, Divider, Flex, Image, Img, Text } from "@chakra-ui/react";
import React from "react";

import { logoAuth,signInLogo3 } from "../../../assets/images";
import LoginForm from "./components/LoginForm";
import useIsMobile from "../../../hooks/use-is-mobile";
import { useTranslation } from "react-i18next";
import { facebookIcon, googleIcon, guest } from "../../../assets/images";
import usePostData from "../../../api/usePostData";
import { useDispatch } from "react-redux";
import { setAuth } from "../../../redux/reducerSlices/Auth/authSlice";
import { jwtDecode } from "jwt-decode";
import { showToastMessage } from "../../../utils/utilsFunctions";
import { useNavigate } from "react-router-dom";
import SocialLogin from "./components/SocialLogin";
const Login = ({ switchToSignUp }: { switchToSignUp: any }) => {
  const [mobile] = useIsMobile();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { postData, loading } = usePostData();

  const loginAsGest = async function () {
    const res = await postData({
      route: `guest`,
      successMsg: t("Welcome!"),
    });


    if (res?.error) {
      if (res?.data) {
        
        dispatch(
          setAuth({
            token: res?.token,
            authInfo: res?.data,
            authData: jwtDecode(res?.data),
          })
        );

      }
    } else {
      localStorage.setItem("authInfo", JSON.stringify(res?.result));
      localStorage.setItem("token", res?.token);
      dispatch(
        setAuth({
          token: res?.token,
          authInfo: res?.result,
          authData: jwtDecode(res?.token),
        })
      );
      navigate("/");
    }
  };
  return (
    <Flex   justify={"center"} w={"100%"}>
      <Flex w="100%" justify="center" align="center" mt="1rem">

        <Flex
          justify="center"
          align="center"
          direction={mobile ? "column" : "row"}
          // gap="md"
          w={"100%"}
          gap={5}
          h={'100%'}
        
        >
          <Flex
            direction="column"
            align="center"
            w={{base:'100%' ,md:"50%"}}
            mr={"5%"}
          >

            <LoginForm switchToSignUp={switchToSignUp} />
          </Flex>

          <Box height='90%' >
            <Divider
              borderColor='#ddd'

              orientation='vertical' />
          </Box>
          <Flex
            // h={"50%"}
            align={"space-between"}
            justifyContent={"space-between"}
            direction={"column"}
            w={mobile ? "100%" : "28%"}
            //  pl={2}
            // pt={16}
          >
            <Flex direction={"column"} mb={5}>
              <Text color="#586870" mb={2}>
                {t("or Sign In with")}
              </Text>
              <SocialLogin />
            </Flex>
            <Img p={1} borderRadius={"60px"} border={"1px solid #a4020c"} src={signInLogo3} alt="logo" />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Login;
