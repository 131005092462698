import { Badge, Box, Button, Flex, Text } from "@chakra-ui/react";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const OrderCard = ({ order }: { order: any }) => {
  const isDiscount =
    order?.cartOriginalTotal && order?.cartOriginalTotal === order?.cartTotal
      ? false
      : true;
  console.log({ order }, "OrderCard");
  const { t } = useTranslation();

  return (
    <Flex w={"100%"} justify={"center"}>
      <Box
        borderRadius={10}
        px={4}
        py={4}
        mb={4}
        h={170}
        bg={"secondary"}
        border={"0.5px solid #B2B2B2"}
        w={"85%"}
      >
        <Flex h={"100%"} direction={"column"} justifyContent={"space-between"}>
          <Flex justifyContent={"space-between"}>
            <Text fontSize={18}>
              {t("Order Total")} :
              <span style={{ color: "#88050D", marginLeft: "2px" }}>
                {" "}
                {order?.orderTotal} {t("AED")}
              </span>
            </Text>
            <Flex flexDir={"column"} gap={4}>
              <Text fontSize={18}> {t(order?.paymentMethod)}</Text>
              {/* <Badge
                textAlign={"center"}
                width={"100%"}
                colorScheme={
                  order?.subOrders[0]?.status === "pending"
                    ? "yellow"
                    : order?.subOrders[0]?.status === "rejected" ||
                      order?.subOrders[0]?.status === "canceled"
                    ? "red"
                    : "green"
                }
                borderRadius={13}
                py={2}
                px={6}
                mb={4}
                color={"primary"}
                // textTransform="none"
              >
                {t(order?.subOrders[0]?.status?.toLocaleLowerCase())}
              </Badge> */}
            </Flex>
          </Flex>

          <Flex justifyContent={"space-between"}>
            <Text color="gray" fontSize={18}>
              {/* Status :
              <Badge
                ml={2}
                colorScheme={
                  order?.status === "pending"
                    ? "yellow"
                    : order?.status === "rejected"
                    ? "red"
                    : "green"
                }
                borderRadius={15}
                py={2}
                px={6}
                color={"primary"}
              >
                {order?.status}
              </Badge> */}
              {order?.issueDate &&
                moment(order?.issueDate).format("DD/MM/YYYY  hh:mm A")}
            </Text>

            <Link to={`/order-details/${order?._id}`}>
              <Button>{t("Track Order")}</Button>
            </Link>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

export default OrderCard;
