import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Flex, Image, Text, Img } from "@chakra-ui/react"; // Import Chakra UI components as needed
import useGetData from "../../../api/useGetData";
import ApiRoutes from "../../../api/services/api-routes";

import TrendingProductCard from "../../../components/TrendingProductCard";
import AppCarousel from "../../../components/AppCarousel";
import ServiceCard from "../../../components/ServiceCard";
import Rating from "../../../components/Rating";
import RatingAndReview from "../../../components/RatingAndReview";
import { emptyImage, servicesBg } from "../../../assets/images";
import { MdVerified } from "react-icons/md";
import { useTranslation } from "react-i18next";
import useLang from "../../../hooks/useLang";
import Carousel from "react-multi-carousel";

const StoreDetails: React.FC = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const { t, i18n } = useTranslation();
  const { shopId } = useParams<{ shopId: string }>();
  const { data: shopDetailsData, loading: getShopLoading } = useGetData({
    route: ApiRoutes?.getShopById,
    params: { _id: shopId },
  });

  const shopDetails = shopDetailsData?.result;
  const categories = shopDetails?.categories;
  const { lang } = useLang();

  console.log(
    { shopDetails },
    shopDetails?.covers?.[0]?.Location,
    "shopDetails"
  );

  const categoriesName = useMemo(() => {
    if (!categories) {
      return;
    }
    return categories.reduce(
      (total: string, item: any) => total + `${item?.[`name${lang}`]}, `,
      ""
    );
  }, [categories, lang]);

  return (
    <Flex justify={"center"}>
      <Flex width={"75%"}>
        {/* <Flex width={"auto"}> */}
        <Box width={"100%"}>
          {/* <Box
          borderRadius={5}
          borderWidth={1}
          borderColor={"rgba(0, 0, 0, 0.15)"}
          h={{ base: "25vh", md: "45vh" }}
          width={"100%"}
          bgColor={"#F6EEE1"}
          bgImage={shopDetails?.covers?.length === 0 && fallbackSrc}
          bgRepeat={"no-repeat"}
          bgPosition={"center"}
          bgSize={"contain"}
        >
          {shopDetails?.covers?.length > 0 && (
            <Splide
              options={{
                perPage: 1, // Number of slides per page
                autoplay: true,
                interval: 2000,
                pauseOnHover: true,
                pagination: false,
              }}
              aria-label="Shop"
            >
              {shopDetails?.covers &&
                [...shopDetails?.covers]?.map((item: any, index: number) => (
                  <SplideSlide style={{ overflow: "hidden" }} key={index}>
                    <Image
                      h={{ base: "25vh", md: "45vh" }}
                      width={"100%"}
                      src={item?.Location}
                    />
                  </SplideSlide>
                ))}
            </Splide>
          )}
        </Box>
        <Flex
          zIndex={11}
          position={"relative"}
          bottom={{ base: "8vh", md: "18vh" }}
          mx="5%"
          justifyContent={"flex-start"}
          height={"100%"}
          flex={1}
          alignItems={"flex-end"}
        >
          <Box
            borderRadius={5}
            borderWidth={2}
            boxShadow={"0px 2px 24px 0px rgba(0, 0, 0, 0.10)"}
            borderColor={"#A27878"}
            width={{ base: "40vw", md: "40vh" }}
            bgColor={"#F6EEE1"}
            overflow={"hidden"}
          >
            <Box
              bgImage={
                (previewImg
                  ? URL.createObjectURL(previewImg)
                  : shopDetails?.image?.Location) ||
                (!getShopLoading && fallbackSrc)
              }
              height={{ base: "12vh", md: "26vh" }}
              width={{ base: "40wh", md: "40vh" }}
              bgSize={"cover"}
              overflow={"hidden"}
              bgPosition={"center"}
            >
              {shopDetails?.isFood && (
                <Box zIndex={10} position={"absolute"} left={2} top={2}>
                  <Image src={foodIcon} width={"30px"} />
                </Box>
              )}

         
            </Box>
            <Flex
              width={"100%"}
              flexDirection={"column"}
              bgColor={"#F6EEE1"}
              p={{ base: 1, md: 3 }}
              justifyContent={"space-between"}
            >
             
              <Flex
                pt={{ base: 0, md: 2 }}
                alignItems={"center"}
                width={"100%"}
                justifyContent={"space-between"}
                borderTopWidth={1}
                borderColor={"rgba(0, 0, 0, 0.15)"}
              >
                <Text
                  noOfLines={1}
                  fontSize={{ base: "xs", md: "sm" }}
                  fontWeight={"bold"}
                  color={"#625051"}
                >
                  {shopDetails?.[`name${lang}`]?.toUpperCase()}
                </Text>

           
              </Flex>

              <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Text
                  width={"75%"}
                  noOfLines={1}
                  fontSize={"sm"}
                  color={"rgba(79, 79, 79, 0.7)"}
                >
                  {categoriesName}
                </Text>
                <Rating rating={shopDetails?.rating} />
              </Flex>
            </Flex>
          </Box>
        </Flex> */}
          <Box bg={"rgba(246, 244, 244, 0.59)"}>
            {/* <Box
              position="relative"
              // height={{ base: "8em", md: "15em" }}
              height={"497px"}
              // width={"1420px"}
              width="75vw"
              // width={"100%"}
              backgroundImage={
                shopDetails?.covers?.[0]?.Location ||
                (!getShopLoading && emptyImage)
              }
              backgroundRepeat={"no-repeat"}
              backgroundSize={
                shopDetails?.covers?.[0]?.Location ? "cover" : "contain"
              }
              backgroundPosition={"center"}
            >
              <Box
                position={"absolute"}
                top= "60%"
                // top={{ base: "4em", md: "10em" }}
                left={{ base: "2em", md: "4em" }}
                borderRadius={5}
                borderWidth={2}
                boxShadow={"0px 2px 24px 0px rgba(0, 0, 0, 0.10)"}
                borderColor={"#A27878"}
                // width={{ base: "40vw", md: "40vh" }}
                width={"371px"}
                minWidth={"371px"}
                bgColor={"#F6EEE1"}
                overflow={"hidden"}
              >
                <Image
                  src={
                    shopDetails?.image?.Location ||
                    (!getShopLoading && emptyImage)
                  }
                  height="100%"
                  maxHeight={"233px"}
                  width="100%"
                  // width={"371px"}
                  // minWidth={"371px"}
                  // w={{ base: "100%", md: "100%" }}
                  objectFit={"cover"}
                  bg={"rgba(246, 244, 244, 0.59)"}
                />
                <Flex
                  width={"100%"}
                  flexDirection={"column"}
                  bgColor={"#F6EEE1"}
                  p={{ base: 1, md: 3 }}
                  justifyContent={"space-between"}
                >
                  <Flex
                    pt={{ base: 0, md: 2 }}
                    alignItems={"center"}
                    width={"100%"}
                    justifyContent={"space-between"}
                    borderTopWidth={1}
                    borderColor={"rgba(0, 0, 0, 0.15)"}
                  >
                    <Text
                      noOfLines={1}
                      fontSize={{ base: "xs", md: "sm" }}
                      fontWeight={"bold"}
                      color={"#625051"}
                    >
                      {shopDetails?.[`name${lang}`]?.toUpperCase()}
                    </Text>
                  </Flex>

                  <Flex justifyContent={"space-between"} alignItems={"center"}>
                    <Text
                      width={"75%"}
                      noOfLines={1}
                      fontSize={"sm"}
                      color={"rgba(79, 79, 79, 0.7)"}
                    >
                      {categoriesName}
                    </Text>
                    <Rating reviewCount={shopDetails?.reviewCount} rating={shopDetails?.rating} />
                  </Flex>
                </Flex>
              
              </Box>
            </Box> */}
            <Box
              position="relative"
              height={"497px"}
              width="75vw"
              // backgroundImage={
              //   shopDetails?.covers?.[0]?.Location ||
              //   (!getShopLoading && emptyImage)
              // }
              // backgroundRepeat={"no-repeat"}
              // backgroundSize={
              //   shopDetails?.covers?.[0]?.Location ? "cover" : "contain"
              // }
              // backgroundPosition={"center"}
            >
              {shopDetails && (
                <Carousel
                  rtl={i18n.language === "en" ? false : true}
                  responsive={responsive}
                >
                  {shopDetails?.covers?.map((cover: any) => (
                    <Box key={cover?.key}>
                      <Img
                        bgColor={"#F9F0E3"}
                        maxHeight={"497px"}
                        height={"497px"}
                        width="75vw"
                        src={cover?.Location}
                        objectFit={"cover"}
                        objectPosition={"center"}
                      />
                    </Box>
                  ))}
                </Carousel>
              )}
              <Box
                position={"absolute"}
                top="60%"
                // top={{ base: "4em", md: "10em" }}
                left={{ base: "2em", md: "4em" }}
                borderRadius={5}
                borderWidth={2}
                boxShadow={"0px 2px 24px 0px rgba(0, 0, 0, 0.10)"}
                borderColor={shopDetails?.isTrusted ? "rgb(29, 191, 215)" : "#A27878"}
                // width={{ base: "40vw", md: "40vh" }}
                width={"371px"}
                minWidth={"371px"}
                bgColor={"#F6EEE1"}
                overflow={"hidden"}
              >
                <Image
                  src={
                    shopDetails?.image?.Location ||
                    (!getShopLoading && emptyImage)
                  }
                  height="100%"
                  maxHeight={"233px"}
                  width="100%"
                  // width={"371px"}
                  // minWidth={"371px"}
                  // w={{ base: "100%", md: "100%" }}
                  objectFit={"cover"}
                  bg={"rgba(246, 244, 244, 0.59)"}
                />
                <Flex
                  width={"100%"}
                  flexDirection={"column"}
                  bgColor={"#F6EEE1"}
                  p={{ base: 1, md: 3 }}
                  justifyContent={"space-between"}
                >
                  <Flex
                    pt={{ base: 0, md: 2 }}
                    alignItems={"center"}
                    width={"100%"}
                    justifyContent={"space-between"}
                    borderTopWidth={1}
                    borderColor={"rgba(0, 0, 0, 0.15)"}
                  >
                    <Text
                      noOfLines={1}
                      fontSize={{ base: "xs", md: "sm" }}
                      fontWeight={"bold"}
                      color={"#625051"}
                    >
                      {shopDetails?.[`name${lang}`]?.toUpperCase()}
                    </Text>
                  </Flex>

                  <Flex justifyContent={"space-between"} alignItems={"center"}>
                    <Text
                      width={"75%"}
                      noOfLines={1}
                      fontSize={"sm"}
                      color={"rgba(79, 79, 79, 0.7)"}
                    >
                      {categoriesName}
                    </Text>
                    <Flex gap={1} alignItems={"center"}>
                      <Rating
                        reviewCount={shopDetails?.reviewCount}
                        rating={shopDetails?.rating}
                      />
                      {shopDetails?.isTrusted && <MdVerified color="rgb(29, 191, 215)" />}
                      {/* {shopDetails?.isTrusted && <MdVerified color="#008bff" />} */}
                    </Flex>
                  </Flex>
                </Flex>
                {/* <Image
                position={"absolute"}
                top={{ base: "4em", md: "10em" }}
                left={{ base: "2em", md: "4em" }}
                src={
                  shopDetails?.image?.Location ||
                  (!getShopLoading && emptyImage)
                }
                height="100%"
                w={{ base: "40%", md: "24%" }}
                objectFit={"contain"}
                bg={"rgba(246, 244, 244, 0.59)"}
              /> */}
              </Box>
            </Box>
            <Box></Box>
          </Box>

          <Box marginTop={"18em"} width={"100%"} px={{ base: 2, md: 8 }}>
            {shopDetails?.type === "product" && (
              <Flex
                mt={8}
                p={{ base: 2, md: 8 }}
                bg={"rgba(246, 244, 244, 0.59)"}
                width={"100%"}
              >
                <AppCarousel
                  dataType="Products"
                  // outerResponsive={responsive}
                  sectionRoute={`/all-products?shopId=${shopId}`}
                  sectionTitle={t("Products")}
                  params={{
                    shop: shopId,
                  }}
                  route={ApiRoutes?.listProducts}
                  renderItem={(item) => (
                    <TrendingProductCard
                      width={{ base: "100%", md: "90%" }}
                      product={item}
                    />
                  )}
                />
              </Flex>
            )}

            {shopDetails?.type === "service" && (
              <Flex
                alignItems={"flex-start"}
                bg={"rgba(246, 244, 244, 0.59)"}
                mt={16}
                p={{ base: 2, md: 8 }}
                backgroundImage={servicesBg}
              >
                <AppCarousel
                  dataType="Services"
                  sectionRoute={`/all-services?shopId=${shopId}`}
                  sectionTitle="Services"
                  route={ApiRoutes?.listServices}
                  params={{ shop: shopId }}
                  renderItem={(item) => (
                    <ServiceCard
                      width={{ base: "100%", md: "90%" }}
                      service={item}
                    />
                  )}
                />
              </Flex>
            )}
          </Box>
          {shopDetails && (
            <Flex mt="2em">
              <RatingAndReview
                totalRating={shopDetails?.rating}
                type="shop"
                typeId={shopDetails?._id}
              />
            </Flex>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

export default StoreDetails;
