import React, { useEffect, useState } from "react";
import { Box, Text, Flex, Container, SimpleGrid } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import CategoryCard from "../../components/CategoryCard";

import useGetData from "../../api/useGetData";
import ApiRoutes from "../../api/services/api-routes";
import DropDownList from "../../components/DropdownList";
import AppSkeleton from "../../common/AppSkeleton";
import NoDataFound from "../../components/NoDataFound";
import ShowMore from "../../common/ShowMore";

interface Category {
  id: number;
  name: string;
  // Add any other properties needed for a category
}

interface AllCategoriesProps {
  categories?: Category[]; // Array of category objects
}

const AllCategories: React.FC<AllCategoriesProps> = ({}) => {
  const { t } = useTranslation();
  const [filterType, setFilterType] = useState({
    label: t("Shop"),
    value: "shop",
  });
  const {
    data: allCategoriesData,
    loading,
    loadingMoreData,
    loadMoreData,
    getData,
  } = useGetData({
    route: ApiRoutes?.listCategories,
    // params: { type: filterType?.value, limit: 20 },
    params: { type: "shop", isSubCategory: false, limit: 8 },

  });

  const filterTypeItems = [
    { label: t("Product"), value: "product" },
    { label: t("Shop"), value: "shop" },
    { label: t("Service"), value: "service" },
  ];
  const onChangType = (
    value: React.SetStateAction<{ label: string; value: any }>
  ) => {
    setFilterType(value);
    // getData({
    //   route: ApiRoutes?.listCategories,
    //   params: { type: filterType?.value },
    // });
  };

  useEffect(() => {
    getData({
      route: ApiRoutes?.listCategories,
      // params: { type: filterType?.value, limit: 8 },
      params: { type: "shop", isSubCategory: false, limit: 8 },
    });
  }, [filterType]);

  if (loading) {
    return (
      <Flex justify={"center"} py={16}>
        <Flex width={"75%"} direction={"column"}>
          <Flex justify={"flex-end"}>
            <AppSkeleton width={"100%"} height={"20"} />
            {/* Add any action buttons or links here */}
          </Flex>
          <Container>
            <SimpleGrid columns={[1, 2, 3, 4, 5]} spacing={6} width="100%">
              {allCategoriesData?.result
                ?.filter((key: any) => key?.isSubCategory === false)
                // {allCategoriesData?.result?.filter((key:any) => key?.type !== "shop" && key?.type !== "service" && key?.isSubCategory === false)
                .map((category: any, index: number) => (
                  <AppSkeleton
                    width={{ base: 100, md: 140 }}
                    height={{ base: 100, md: 140 }}
                    borderRadius={"50%"}
                  />
                ))}
            </SimpleGrid>
          </Container>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex justify={"center"} py={16}>
      <Flex width={"75%"} direction={"column"}>
        <Flex justify={"flex-start"}>
          <Flex
            display={{ base: "block", md: "flex" }}
            w={{ base: "100%", md: "60%" }}
            mb={20}
            justify={"space-between"}
            align={"center"}
          >
            {/* <Text
              mt={4}
              mb={4}
              fontSize={{base:20 , md:30}}
              fontWeight={"600"}
              color={"primary"}
              align="center"
            >
              {t('All Categories')}
            </Text> */}
            {/* <DropDownList
              style={{ flex: 0 }}
              item={filterTypeItems}
              onChange={onChangType}
              value={filterType}
            /> */}
            {/* Add any action buttons or links here */}
          </Flex>
        </Flex>

        <Container>
          {allCategoriesData?.result ? (
            <>
              <SimpleGrid columns={[1, 2, 3, 4, 5]} spacing={6} width="100%">
                {/* {allCategoriesData?.result?.filter((key:any) => key?.type !== "shop" && key?.type !== "service" && key?.isSubCategory === false)  */}
                {allCategoriesData?.result
                  ?.filter((key: any) => key?.isSubCategory === false)
                  .map((category: any, index: number) => (
                    <CategoryCard category={category} />
                  ))}
              </SimpleGrid>
              <Box display="flex" justifyContent="center" mt={4}>
                {/* <ShowMore
                  style={{ width: "100%", marginTop: 60 }}
                  data={allCategoriesData?.result}
                  loadMoreData={loadMoreData}
                  loading={loadingMoreData}
                  count={allCategoriesData?.count}
                /> */}
              </Box>
              {/* <ShowMore
                data={allCategoriesData?.result}
                loadMoreData={loadMoreData}
                loading={loadingMoreData}
                count={allCategoriesData?.count}
              /> */}
            </>
          ) : (
            <NoDataFound data="Categories" />
          )}
        </Container>
      </Flex>
    </Flex>
  );
};

export default AllCategories;
