import { Toast } from "@chakra-ui/react";
import i18n from "../i18n";
import { t } from "i18next";
import { T } from "./ValiidationSchemas";
import store, { RootState } from "../redux/store";
import { GetService } from "../api/services/requests-service";
import ApiRoutes from "../api/services/api-routes";
import Axios from "../api/services/axios";
import axios from "axios";

export function console_log(...args: any[]): void {
  console.log(...args);
}

// Helper function to create a generic alert
const showAlert = (toast: any, status: "error" | "success" | "warning" | "info", msg: any) => {
  if (msg === "canceled" || msg === "" || !msg) return;
  toast({
    position: "top-right",
    // title: status === 'error' ? 'Error' : 'Success',
    description: msg,
    status,
    duration: 1500,
    isClosable: true,
  });
};

// Function to show error message
export const showMSG = (
  toast: any,
  status: "error" | "success" | "warning" | "info",
  msg: any
) => {
  return showAlert(toast, status, msg);
};

// ==delete any key in object has a undefined value==
export const paramsValidate = (params: any) => {
  if (params) {
    for (const [key, value] of Object.entries(params)) {
      if (value === undefined) {
        delete params[key];
      }
      // console.log(`${key}: ${value}`);
    }
  }
  return params;
};


export function convertArabicToEnglishNumerals(input: string): string {
  const arabicNumerals: string[] = [
    "٠",
    "١",
    "٢",
    "٣",
    "٤",
    "٥",
    "٦",
    "٧",
    "٨",
    "٩",
  ];
  return input?.replace(/[٠-٩]/g, (d) => arabicNumerals.indexOf(d).toString());
}


export const lang = i18n.language === "ar" ? "Ar" : "En";

// =========================================================
//Compare to object and return new object of changed values
//===========================================================
function isObject(value: any): boolean {
  return (
    typeof value === "object" && value !== null && !(value instanceof Array)
  );
}
type Change = any;
export function compareObjects({
  oldObject,
  newObject,
}: {
  oldObject: any;
  newObject: any;
}): Change {
  const changes: Change = {};
  for (const key in newObject) {
    //=== if type of value is array==
    if (Array.isArray(oldObject?.[key]) && Array.isArray(newObject?.[key])) {
      if (oldObject?.[key].length !== newObject?.[key].length) {
        //not the same length
        changes[key] = newObject?.[key];
      } else if (
        !newObject?.[key].every(
          (val: any, index: number) =>
            val?._id === oldObject?.[key]?.[index]?._id ||
            val === oldObject?.[key]?.[index]
        )
      ) {
        //map array to check if any value is changed
        changes[key] = newObject?.[key];
      }
    } else if (isObject(oldObject?.[key]) && isObject(newObject?.[key])) {
      // if value is object
      changes[key] = newObject?.[key];
    } else {
      //=== if type of value is not  Array or object==
      if (newObject?.[key] !== oldObject?.[key]) {
        changes[key] = newObject?.[key];
      }
    }
  }

  return changes;
}
/// handle returned product to set first variation  as a default variation if there is no one
export const defaultVariationOfProducts = (allProducts: any) => {
  const variations = allProducts?.map((product: any) => {
    const productName = product?.[`name${lang}`];
    const shopName = product?.shop?.[`name${lang}`];
    const productId = product?._id;
    const rating = product?.rating;
    let variation;

    if (product?.defaultVariation) {
      variation = {
        ...product?.defaultVariation,
        productName,
        shopName,
        productId,
        rating,
      };
    } else if (product?.variations && product?.variations.length > 0) {
      variation = {
        ...product?.variations[0],
        productName,
        shopName,
        productId,
        rating,
      };
    }

    return variation;
  });

  return variations;
};

export default function moveObjectToTop(array: any, customerId: string) {
  // Find the index of the object with the specified customer ID
  const index = array.findIndex(
    (obj: any) => obj?.customer?._id === customerId
  );

  // If the customer ID is not found, return the original array
  if (index === -1) {
    return array;
  }

  // Remove the object from its current position and store it in a variable
  const movedObject: any = array.splice(index, 1)[0];

  movedObject.customer.name = t("Your Review");
  movedObject.customer.isFound = true;
  // Add the object back to the array at the beginning
  array.unshift(movedObject);

  return array;
}
export const showToastMessage = (message: string) => {
  Toast({
    title: message,
    status: "success",
    duration: 3000,
    isClosable: true,
  });
};

// =========validated Dynamic Form Fields=======
export const validatedDynamicFormFields = ({
  t,
  fields,
}: {
  t: T;
  fields: any[];
}) => {
  let error = false;
  const updatedData = fields?.map((item: any, index: number) => {
    if (item?.isRequired) {
      if (item?.type !== "enum" && !item?.value) {
        error = true;
        return { ...item, error: item?.isRequired ? t("Required*") : null };
      } else {
        const isValid = item?.value?.en || item?.value?.number || item?.value;

        if (isValid) {
          console_log(
            "isValid",
            item?.value?.en,
            item?.value?.number,
            item?.value
          );

          return { ...item, error: "" };
        } else {
          error = true;
          return { ...item, error: item?.isRequired ? t("Required*") : null };
        }
      }
    } else {
      return { ...item, error: "" };
    }
  });

  if (error) {
    return updatedData;
  } else {
    return true;
  }
};

// ====onChange Dynamic Form Field Value ===
export type onChangeDynamicFormFieldValueProps = {
  fields: any[];
  type?: "ar" | "en";
  isEnum: boolean;
  value: any;
  index: number;
  item: any;
};
export const onChangeDynamicFormFieldValue = ({
  fields,
  value,
  index,
  item,
  isEnum,
  type,
}: onChangeDynamicFormFieldValueProps) => {
  if (isEnum) {
    const updatedField = [...fields];
    updatedField[index] = { ...item, value: value, error: "" };
    return updatedField;
    // setFields(updatedField)
  } else {
    const updatedField = [...fields];
    const isError =
      item?.isRequired && (value?.length > 0 ? false : "Required");

    if (type) {
      updatedField[index] = {
        ...item,
        value: {
          ...item.value,
          [type]: value,
        },
        error: isError,
      };
    } else {
      updatedField[index] = {
        ...item,
        value: {
          number: value,
        },
        error: isError,
      };
    }

    return updatedField;
  }
};

export const mergeObjectsWithDifferences = (
  baseObject: Record<string, any>,
  differences: Record<string, any>
) => {
  return Object.keys(differences).reduce(
    (acc, key) => {
      if (baseObject[key] !== differences[key]) {
        acc[key] = differences[key];
      } else {
        acc[key] = baseObject[key];
      }
      return acc;
    },
    { ...baseObject }
  );
};

//////get each length ///
export const getCartQuantity = async () => {
  try {
    const customerId = store?.getState()?.auth?.authData?._id;

    const response = await GetService({
      route: ApiRoutes?.getCart,
      params: { customer: customerId },
    });

    const totalQuantities = response.data?.result?.subCarts?.map(
      (subCart: any) =>
        subCart.items.reduce(
          (total: any, item: any) => total + item?.quantity,
          0
        )
    );
    // Calculate the total sum of quantities in all subCarts
    const totalSum = totalQuantities.reduce(
      (total: any, sum: any) => total + sum,
      0
    );

    return totalSum; // Adjust this based on your API response structure
  } catch (error) {
    console.error("Error fetching cart quantity:", error);
    return 0; // Return a default value or handle the error as needed
  }
};

export const getBasketQuantity = async () => {
  try {
    const customerId = store?.getState()?.auth?.authData?._id;
    const response = await GetService({
      route: ApiRoutes?.getBasket,
      params: { customer: customerId },
    });

    const totalQuantities = response.data?.result?.subCarts?.map(
      (subCart: any) =>
        subCart.items.reduce(
          (total: any, item: any) => total + item?.quantity,
          0
        )
    );
    // Calculate the total sum of quantities in all subCarts
    const totalSum = totalQuantities.reduce(
      (total: any, sum: any) => total + sum,
      0
    );

    return totalSum; // Adjust this based on your API response structure
  } catch (error) {
    console.error("Error fetching basket quantity:", error);
    return 0; // Return a default value or handle the error as needed
  }
};

export const getServicesLength = async () => {
  try {
    const customerId = store?.getState()?.auth?.authData?._id;

    const response = await GetService({
      route: ApiRoutes?.listServices,
      // params: { customer: customerId },
    });

    return response.data?.result?.length; // Adjust this based on your API response structure
  } catch (error) {
    console.error("Error fetching services length:", error);
    return 0; // Return a default value or handle the error as needed
  }
};

export const getTotalCartLength = async () => {
  try {
    // Dispatch the individual thunks and await their completion
    const lengthOfCart = await getCartQuantity();
    const lengthOfBasket = await getBasketQuantity();
    const lengthOfServices = await getServicesLength();
    const total = lengthOfCart + lengthOfBasket + lengthOfServices;

    return total;
  } catch (error) {
    console.error("Error calculating total cart length:", error);
    return 0; // Return a default value or handle the error as needed
  }
};

export const socialAuth = async ({
  name,
  email,
  phone,
  token,
}: {
  name?: string | null | any;
  email?: string | any;
  phone?: any;
  token: any;
}) => {
  Axios.interceptors.request.use(async (req: any) => {
    req.headers.Authorization = `Bearer ${token}`;

    return req;
  });
  const data = {
    name: name,
    email: email,
    // "isPhoneVerified":phone
  };

  //   return;

  try {
    const res = await Axios.post("social", data);

    return res.data;
  } catch (error: any) {
    return { error: error?.response?.data?.error };
  }
};
export const validatePhoneNumber = (value: string) => {
  // const emiratePhoneNumberRegex = /^971(50|52|54|55|56|58)[1-9]{7}$/;
  const emiratePhoneNumberRegex = /^971[1-9]{5,10}$/;
  // if (value?.length !== 13) {
  //   return "Phone number must be 13 digits long";
  // } else
  if (!emiratePhoneNumberRegex.test(value)) {
    return i18n.t("Invalid UAE phone number");
  }
};

// First flight listEmirates

export const listEmirates = async () => {
  const lang = "En";
  try {
    const response = await Axios.get(
      "https://ifast-shipperapi.dispatchex.com/api/CommonAPI/Cities",
      {
        params: {
          countryID: 1,
        },
      }
    );
    console.log(response);
    const emirates = response?.data?.data?.filter(
      (city: any) => city?.code !== "OSA"
    );
    const filteredEmirates = emirates?.map((city: any) =>
      lang === "En"
        ? city.name
        : city.name_Arabic !== null
        ? city.name_Arabic
        : city?.name
    );
    console.log("filteredEmirates", filteredEmirates);
    // .map((city: any) => (
    //  // id: city.city_ID,
    //  city?.code !=="OSA" &&(lang === "En" ? city?.name : city?.name_Arabic || city?.name)
    // ));

    return filteredEmirates;
  } catch (err) {
    console.error(err);
  }
};
