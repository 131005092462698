import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Box,
  Heading,
  Input,
  Button,
  Image,
  Textarea,
  VStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Flex,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import { cursor } from "../../assets/images";
import usePostData from "../../api/usePostData";

const ComplaintsForm = () => {
  const complaintsUrl =
    "https://sona3-test-3918329fd6a7.herokuapp.com/api/v1/informative/complaints/create";
  const { postData } = usePostData();
  const { t,i18n } = useTranslation();

  const initialValues = {
    name: "",
    email: "",
    body: "",
    creationDate: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(t("Name is required")),
    email: Yup.string()
      .email(t("Invalid email address"))
      .required(t("Email is required")),
    body: Yup.string().required(t("Suggestion is required")),
  });

  const onSubmit = async (values: any, { setSubmitting, resetForm }: any) => {
    setSubmitting(true);

    const finalData = { ...values, creationDate: new Date().toISOString() };

    const res = await postData({
      route: complaintsUrl,
      data: finalData,
      successMsg: t("Your suggestion has been sent successfully"),
    });

    if (res?.error) {
      console.error("Submission error:", res.error);
    } else {
      console.log("Submission success:", res);
      resetForm();
    }

    setSubmitting(false);
  };

  return (
    <Flex
      bgSize="cover"
      justifyContent={"center"}
      bgRepeat="no-repeat"
      borderRadius="15px"
      style={{
        direction: i18n.language === "en" ? "ltr" : "rtl",
        // fontFamily:
        //   `${i18n.language === "en" ? "JosefinSansRegular" : "ArabicFont"} !important`,
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <VStack
              spacing={5}
              bgImage="url('/assets/imgs/form_frame.png')"
              bgSize="cover"
              bgRepeat="no-repeat"
              p={8}
              borderRadius="15px"
            >
              <Box textAlign="center">
                <Heading as="h2" size="md" mb={4}>
                  {t(
                    "Send us your suggestions and opinions on a regular basis to help us continuously develop"
                  )}
                </Heading>
                <Image src={cursor} alt="Cursor" width="30px" mx="auto" />
              </Box>

              <FormControl isInvalid={!!errors.name && touched.name}>
                <FormLabel htmlFor="name">{t("Name")}</FormLabel>
                <Field
                  as={Input}
                  id="name"
                  name="name"
                  placeholder={t("Enter your name")}
                />
                {touched.name && errors.name && (
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={!!errors.email && touched.email}>
                <FormLabel htmlFor="email">{t("Email")}</FormLabel>
                <Field
                  as={Input}
                  id="email"
                  name="email"
                  placeholder={t("Enter your email")}
                />
                {touched.email && errors.email && (
                  <FormErrorMessage>{errors.email}</FormErrorMessage>
                )}
              </FormControl>

              <FormControl isInvalid={!!errors.body && touched.body}>
                <FormLabel htmlFor="body">{t("Your Suggestion")}</FormLabel>
                <Field
                  as={Textarea}
                  id="body"
                  name="body"
                  placeholder={t("Enter your suggestion")}
                  resize="none"
                  rows={4}
                />
                {touched.body && errors.body && (
                  <FormErrorMessage>{errors.body}</FormErrorMessage>
                )}
              </FormControl>

              <Button
                type="submit"
                bg={"primary"}
                isLoading={isSubmitting}
                width="full"
              >
                {isSubmitting ? t("Submitting...") : t("Send")}
              </Button>
            </VStack>
          </Form>
        )}
      </Formik>
    </Flex>
  );
};

export default ComplaintsForm;
